import { FC } from 'react'

import { styled } from '@liftfoils/styles'
import {
  Container,
  EditorialPortableText,
  EditorialBlockWrap,
} from '@liftfoils/components'
import { SectionEditorialContentProps } from '@liftfoils/models'

const Wrap = styled('div', {
  overflow: 'hidden',
  variants: {
    theme: {
      dark: {
        backgroundColor: '$navy800',
      },
      light: {
        backgroundColor: 'white',
        $py: 'M',
      },
    },
    headerPadding: {
      true: {
        pt: 'calc($headerHeight * 2)',
      },
    },
  },
})

export const SectionEditorialContent: FC<SectionEditorialContentProps> = ({
  title,
  content,
  theme = 'light',
  priority,
  shopifyData,
}) => {
  return (
    <Wrap theme={theme} headerPadding={priority}>
      <Container>
        <EditorialBlockWrap theme={theme}>
          {title && <h1>{title}</h1>}
          {content && (
            <EditorialPortableText
              content={content}
              shopifyData={shopifyData}
            />
          )}
        </EditorialBlockWrap>
      </Container>
    </Wrap>
  )
}
