import { Button, Modal } from '@liftfoils/components'
import { ProductOptionProps } from '../ProductOption'
import { useRef, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { ArrowLeft, ArrowRight, CloseXL } from '@liftfoils/icons'
import { CardSlide } from './CardSlide'

const Root = styled('div', {
  display: 'grid',
  gridTemplateRows: 'min-content 1fr',
  height: '100%',
  overflow: 'auto',
})

const ModalHeading = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  margin: '$9 $8',

  '@md': {
    margin: '$9 $9',
  },
})

const ProductTitle = styled('h1', {
  lift_font: 'heading02',
})

const GuidanceTitle = styled('span', {
  display: 'block',
  color: '$gray500_06',

  '@sm': {
    marginLeft: '$4',
    display: 'inline',
  },
})

const ControlsWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$6',
  alignItems: 'center',
})

const CloseButton = styled(Button, {
  alignSelf: 'start',
  padding: '$3',

  '@sm': {
    alignSelf: 'center',
  },
})

const SliderControls = styled('div', {
  display: 'none',

  '@md': {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    justifyContent: 'end',
    gap: '$5',
  },
})

const ControlButton = styled(Button, {
  backgroundColor: '$gray500_01',

  '&:disabled': {
    color: '$gray300',
    backgroundColor: '$gray200',
  },
})

const Slider = styled('div', {
  display: 'flex',
  overflowX: 'auto',
  overflowY: 'auto',
  scrollSnapType: 'x mandatory',
  scrollbarWidth: 'none',
  width: '100%',
  position: 'relative',
  borderTop: '1px solid $gray500_01',
})

const CardsWrap = styled('div', {
  display: 'grid',
  position: 'relative',
  height: '100%',
  width: '100%',
  gridAutoFlow: 'column',
  gridAutoColumns: 'minmax(340px,1fr)',

  '@md': {
    gridAutoColumns: 'minmax(367px,1fr)',
  },
})

type GuidanceModalProps = {
  productTitle?: string
  onOptionChange: (optionId: string, groupId: string) => void
  optionsToSelect?: ProductOptionProps[]
  modalState: {
    isOpen: boolean
    title?: string
    productOptionValuesIndex?: number
  }
  handleClose: () => void
}

export const GuidanceModal = ({
  handleClose,
  productTitle,
  modalState,
  onOptionChange,
  optionsToSelect,
}: GuidanceModalProps) => {
  const { title, isOpen, productOptionValuesIndex } = modalState

  const sliderRef = useRef<HTMLDivElement | null>(null)
  const [isFirstSlideVisible, setIsFirstSlideVisible] = useState(true)
  const [isLastSlideVisible, setIsLastSlideVisible] = useState(false)

  const productOptionValues = optionsToSelect
    ? optionsToSelect[productOptionValuesIndex ?? 0].values
    : null

  const scrollBySlides = (number: number) => {
    if (sliderRef.current) {
      const slides = sliderRef.current?.firstChild
        ?.childNodes as NodeListOf<Element>
      const slideWidth = slides[0].getBoundingClientRect().width
      const gutterWidth =
        slides[1].getBoundingClientRect().left -
        slides[0].getBoundingClientRect().right

      let destination = 0
      destination = (slideWidth + gutterWidth) * number

      sliderRef.current?.scrollBy({
        left: destination,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Modal
      preventScroll={true}
      isOpen={isOpen}
      size={'stretch'}
      onRequestClose={handleClose}
    >
      <Root>
        <ModalHeading>
          {productTitle && (
            <ProductTitle>
              {productTitle}
              {title && <GuidanceTitle>{title}</GuidanceTitle>}
            </ProductTitle>
          )}

          <ControlsWrap>
            {!(isFirstSlideVisible && isLastSlideVisible) && (
              <SliderControls>
                <ControlButton
                  onClick={() => scrollBySlides(-1)}
                  appearance={'bareGray'}
                  size={'small'}
                  disabled={isFirstSlideVisible}
                >
                  <ArrowLeft />
                </ControlButton>
                <ControlButton
                  onClick={() => scrollBySlides(1)}
                  appearance={'bareGray'}
                  size={'small'}
                  disabled={isLastSlideVisible}
                >
                  <ArrowRight />
                </ControlButton>
              </SliderControls>
            )}

            <CloseButton appearance={'flex'} onClick={handleClose}>
              <CloseXL />
            </CloseButton>
          </ControlsWrap>
        </ModalHeading>

        <Slider ref={sliderRef}>
          <CardsWrap>
            {productOptionValues &&
              productOptionValues.map((option, index) => (
                <CardSlide
                  key={`${title}-${index}`}
                  card={option}
                  handleClose={handleClose}
                  onOptionChange={onOptionChange}
                  slider={sliderRef}
                  handleInView={
                    index === 0
                      ? setIsFirstSlideVisible
                      : index === productOptionValues.length - 1
                      ? setIsLastSlideVisible
                      : null
                  }
                />
              ))}
          </CardsWrap>
        </Slider>
      </Root>
    </Modal>
  )
}
