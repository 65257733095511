import { useState, FC } from 'react'
import { GuidanceModal } from './GuidanceModal/GuidanceModal'
import { ProductOption, ProductOptionProps } from './ProductOption'
import { styled } from '@liftfoils/styles'
import { ShopifyResolvedVariantBasic } from '@liftfoils/shopify-sdk'

type GuidanceModalType = {
  title?: string
  productOptionValuesIndex?: number
  isOpen: boolean
}

const Root = styled('div', {
  gridArea: 'options-area',
  display: 'grid',
  gridGap: '$8',
  bg: 'white',
  px: '$containerMargin',
  pb: '$10',
  '@lg': {
    p: '0',
    bg: 'none',
  },
})

export const OptionsArea: FC<{
  optionsToSelect: ProductOptionProps[]
  shopifyVariants: ShopifyResolvedVariantBasic[] | undefined
  onOptionChange: (optionId: string, groupId: string) => void
  productTitle?: string
}> = ({ onOptionChange, productTitle, optionsToSelect, shopifyVariants }) => {
  const [modalState, setModalState] = useState<GuidanceModalType>({
    title: '',
    isOpen: false,
  })

  const openGuidanceModal = ({ title, productOptionValuesIndex }: any) =>
    setModalState({
      title,
      productOptionValuesIndex,
      isOpen: true,
    })

  return (
    <>
      <Root>
        {optionsToSelect.map((option, index) => {
          const isFirstOption: boolean = index === 0
          const previousIndex = index - 1
          const previousOption =
            index >= 0 ? optionsToSelect[previousIndex] : null

          const doesPreviousOptionHaveSelectedValues =
            previousOption &&
            previousOption.values.some((value) => value.selected)

          const isOptionActive =
            doesPreviousOptionHaveSelectedValues || isFirstOption

          return (
            <ProductOption
              {...option}
              variants={shopifyVariants}
              onChange={onOptionChange}
              index={index}
              key={option.id}
              openGuidanceModal={openGuidanceModal}
              isActive={isOptionActive}
            />
          )
        })}
      </Root>
      <GuidanceModal
        onOptionChange={onOptionChange}
        productTitle={productTitle}
        modalState={modalState}
        optionsToSelect={optionsToSelect}
        handleClose={() =>
          setModalState({
            ...modalState,
            isOpen: false,
          })
        }
      />
    </>
  )
}
