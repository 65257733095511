import { Button, Media } from '@liftfoils/components'
import { ArrowLeft, ArrowRight } from '@liftfoils/icons'
import { Model3DViewer } from './Model3D'
import { styled } from '@liftfoils/styles'
import { GRADIENT_BG } from '../config'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'
import debounce from 'lodash/debounce'
import { MediaFieldType } from '@liftfoils/models'

const MobileSlider = styled('div', {
  display: 'flex',
  height: '100%',
  width: '100%',
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  position: 'absolute',
  top: '0',

  '& > *': {
    flexShrink: '0',
  },

  '@md': {
    display: 'none',
  },

  variants: {
    isSnapEnabled: {
      true: {
        scrollSnapAlign: 'center',
      },
      false: {
        scrollSnapAlign: 'unset',
      },
    },
  },
})
const Root = styled('div', {
  display: 'grid',
  gridArea: 'gallery',
  top: '$headerHeight',
  bg: GRADIENT_BG,
  '@md': {
    px: '$containerMargin',
  },
  '@lg': {
    px: '0',
    top: '0',
    position: 'relative',
    bg: 'none',
    height: '100%',
  },
})
const ProductGalleryButtonWrap = styled('div', {
  gridArea: '1/1',
  zIndex: 3,
  alignSelf: 'center',
  display: 'none',

  '@md': {
    display: 'inline-flex',
  },
})

const GallerySectionWrap = styled('div', {
  display: 'grid',
})
const ProductGalleryStickyWrap = styled('div', {
  position: 'sticky',
  top: '$headerHeight',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  height: '80vw',

  '@lg': {
    py: '$headerHeight',
    height: 'calc(100vh - $headerHeight)',
  },
})

const ProductMedia = styled('div', {
  position: 'relative',
  gridRow: 1,
  gridColumn: 1,
  display: 'none',
  opacity: 0,
  transition: 'opacity ease-in 0.2s',
  variants: {
    active: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },

  '@md': {
    display: 'grid',
  },
})

const MobileMediaContainer = styled('div', {
  height: '100%',
  width: '100%',
  scrollSnapAlign: 'center',
})
type MobileMediaWrapProps = {
  slider?: HTMLDivElement | null
  setActiveIndex: (index: number) => void
  index: number
}
const MobileMediaWrap: FC<PropsWithChildren<MobileMediaWrapProps>> = ({
  slider,
  setActiveIndex,
  index,
  children,
}) => {
  const [ref, isInView] = useInView({
    root: slider,
    threshold: 0.9,
  })

  useEffect(() => {
    if (isInView) {
      setActiveIndex(index)
    }
  }, [isInView])

  return <MobileMediaContainer ref={ref}>{children}</MobileMediaContainer>
}

const MobileProductGalleryButtonWrap = styled('div', {
  display: 'flex',
  position: 'absolute',
  right: '5vw',
  bottom: '$8',
  height: '$buttonHeightM',
  alignItems: 'center',

  button: {
    display: 'inline-flex',
    '& + &': {
      marginLeft: '$6',
    },
  },

  '@md': {
    display: 'none',
  },

  variants: {
    isHidden: {
      true: {
        display: 'none',
      },
    },
  },
})

export const ProductGallery: FC<{
  images: MediaFieldType[]
  activeGallerySection: 'photo' | 'model3d'
  current3DModelUUID?: string
  activeProductMediaIndex: number
  setActiveProductMediaIndex: (index: number) => void
}> = ({
  images,
  activeGallerySection,
  current3DModelUUID,
  activeProductMediaIndex,
  setActiveProductMediaIndex,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const [isScrolled, setScrolled] = useState(false)

  const stopScrollAnimation = useCallback(
    debounce(() => setScrolled(false), 800),
    [],
  )

  const scrollBySlides = (numberOfSlides: number) => {
    if (sliderRef?.current) {
      setScrolled(true)
      stopScrollAnimation()
      const currentSlideWidth = sliderRef?.current
        ? sliderRef.current.children[1].clientWidth
        : 0
      sliderRef.current?.scrollBy({
        left: numberOfSlides * currentSlideWidth,
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const switchImage = (index: number) => {
    const newIndex = activeProductMediaIndex + index
    if (newIndex < 0 || newIndex > images.length - 1) return
    setActiveProductMediaIndex(newIndex)
  }

  return (
    <Root>
      <ProductGalleryStickyWrap>
        {activeGallerySection === 'photo' && (
          <GallerySectionWrap>
            {images.length > 1 && (
              <ProductGalleryButtonWrap
                css={{
                  justifySelf: 'start',
                }}
              >
                <Button
                  disabled={activeProductMediaIndex === 0}
                  appearance="solidWhite"
                  size="small"
                  onClick={() => switchImage(-1)}
                >
                  <ArrowLeft />
                </Button>
              </ProductGalleryButtonWrap>
            )}

            {images?.map((image, index) => (
              <ProductMedia
                active={activeProductMediaIndex === index}
                key={`${image.alt}-${index}`}
              >
                <Media
                  {...image}
                  priority={index === 0}
                  objectFit="contain"
                  layout="fill"
                  sizes={'(min-width: 1000px) 70vw, 100vw'}
                  alt={image.alt ?? ''}
                />
              </ProductMedia>
            ))}
            {images.length > 1 && (
              <ProductGalleryButtonWrap
                css={{
                  justifySelf: 'end',
                }}
              >
                <Button
                  disabled={activeProductMediaIndex === images.length - 1}
                  appearance="solidWhite"
                  size="small"
                  onClick={() => switchImage(+1)}
                >
                  <ArrowRight />
                </Button>
              </ProductGalleryButtonWrap>
            )}
            {images.length > 1 && (
              <MobileSlider ref={sliderRef} isSnapEnabled={!isScrolled}>
                {images?.map((image, index: number) => (
                  <MobileMediaWrap
                    index={index}
                    setActiveIndex={setActiveProductMediaIndex}
                    slider={sliderRef?.current}
                    key={`${image.alt}-${index}`}
                  >
                    <Media
                      {...image}
                      priority={index === 0}
                      objectFit="contain"
                      layout="fill"
                      sizes={'(min-width: 1000px) 70vw, 100vw'}
                      alt={image.alt ?? ''}
                    />
                  </MobileMediaWrap>
                ))}
              </MobileSlider>
            )}
          </GallerySectionWrap>
        )}
        {activeGallerySection === 'model3d' && (
          <GallerySectionWrap>
            <Model3DViewer model3dUUID={current3DModelUUID} />
          </GallerySectionWrap>
        )}
        <MobileProductGalleryButtonWrap
          isHidden={activeGallerySection !== 'photo'}
        >
          {images.length > 1 && (
            <Button
              disabled={activeProductMediaIndex === 0}
              appearance="solidWhite"
              size="small"
              onClick={() => scrollBySlides(-1)}
            >
              <ArrowLeft />
            </Button>
          )}
          {images.length > 1 && (
            <Button
              disabled={activeProductMediaIndex === images.length - 1}
              appearance="solidWhite"
              size="small"
              onClick={() => scrollBySlides(+1)}
            >
              <ArrowRight />
            </Button>
          )}
        </MobileProductGalleryButtonWrap>
      </ProductGalleryStickyWrap>
    </Root>
  )
}
