import { styled } from '@liftfoils/styles'
import { SectionProductHeroProps } from '@liftfoils/models'
import { PortableText, Link, Gradient, Media } from '@liftfoils/components'
import { FC } from 'react'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  position: 'relative',
  display: 'grid',
  $posterHeight: true,
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})

const Content = styled('div', {
  px: '$containerMargin',
  gridRow: 1,
  gridColumn: 1,
  zIndex: 1,
  display: 'grid',
  gridTemplateRows: '1fr min-content 1fr',
  py: '$11',
  gridGap: '$8',
  '@md': {
    justifyItems: 'center',
  },
})

const CenterWrap = styled('div', {
  gridRow: 2,
  display: 'grid',
  alignSelf: 'center',
  gridGap: '$8',
})

const Heading = styled('h1', {
  lift_font: 'caps01',
  color: 'white',
  textAlign: 'center',
})
const Description = styled('div', {
  lift_font: 'heading02',
  textAlign: 'center',
  maxWidth: '466px',
  color: '$white_06',
  '.highlighted': {
    color: 'white',
  },
})

const BottomWrap = styled('div', {
  alignSelf: 'end',
  display: 'grid',
  columnGap: '$8',
  rowGap: '$6',
  gridRowStart: 3,
  variants: {
    twoCols: {
      true: {
        '@md': {
          gridTemplateColumns: 'min-content min-content',
          justifyContent: 'center',
        },
      },
    },
  },
})

const ParamsWrap = styled('div', {
  display: 'grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  alignContent: 'center',
  px: '$7',
  height: '$buttonHeightL',
  backdropFilter: 'blur(10px)',
  borderRadius: '$rMax',
  backgroundColor: '$white_01',
  '@md': {
    gridGap: '$10',
    px: '$10',
  },
})

const Param = styled('div', {
  textAlign: 'center',
  whiteSpace: 'nowrap',
})

const Label = styled('div', {
  lift_font: 'body03',
  color: '$white_06',
})

const Value = styled('div', {
  lift_font: 'body03',
  color: '$teal500',
})

export const SectionProductHero: FC<SectionProductHeroProps> = ({
  background,
  title,
  description,
  parameters,
  priority,
  ctaLabel,
  ctaLink,
}) => {
  return (
    <Wrap>
      {background && (
        <MediaWrap>
          <Media
            {...background}
            layout={'fill'}
            priority={priority}
            sizes="100vw"
            hardcropRatio={{
              '856': 'square',
              '1440': 'landscape2',
              '1920': 'landscape2',
              '2880': 'landscape1',
            }}
            controlsPosition={'bottom'}
            alt={background.alt ?? ''}
          />
          <Gradient from={'bottom'} css={{ height: '25%' }} />
        </MediaWrap>
      )}
      <Content>
        <CenterWrap>
          {title && <Heading>{title}</Heading>}
          {description && (
            <Description>
              <PortableText value={description} />
            </Description>
          )}
        </CenterWrap>
        <BottomWrap twoCols={parameters && parameters.length > 0}>
          {parameters && parameters.length > 0 && (
            <ParamsWrap>
              {parameters.map((param) => {
                return (
                  <Param key={param.label}>
                    <Label>{param.label}</Label>
                    <Value>{param.value}</Value>
                  </Param>
                )
              })}
            </ParamsWrap>
          )}

          {validateLink(ctaLink) && (
            <Link appearance={'solidWhite'} size={'large'} {...ctaLink}>
              {ctaLabel}
            </Link>
          )}
        </BottomWrap>
      </Content>
    </Wrap>
  )
}
