export const filters = {
  byName: {
    affiliate: {
      name: 'affiliate',
      label: 'Demo Partner',
      icon: '/pin-affiliate.png',
      iconActive: '/pin-affiliate-active.png',
    },
    'affiliate-reseller': {
      name: 'affiliate-reseller',
      label: 'Demo & Reseller',
      icon: '/pin-affiliate-reseller.png',
      iconActive: '/pin-affiliate-active.png',
    },
    reseller: {
      name: 'reseller',
      label: 'Reseller',
      icon: '/pin-reseller.png',
      iconActive: '/pin-affiliate-active.png',
    },
    'classic-foil-reseller': {
      name: 'classic-foil-reseller',
      label: 'Classic Foil Reseller',
      icon: '/pin-classic-foil-reseller.png',
      iconActive: '/pin-affiliate-active.png',
    },
  },
  names: [
    'affiliate',
    'affiliate-reseller',
    'reseller',
    'classic-foil-reseller',
  ],
} as const
