import { PortableText, Link, StyledClickable } from '@liftfoils/components'
import { EventsLandingEventProps } from '@liftfoils/models'
import { styled } from '@liftfoils/styles'
import { getDurationString } from '@liftfoils/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div', {
  py: '$8',
  backgroundColor: '$navy800',
  display: 'grid',
  gridColumnGap: '$10',
  gridRowGap: '$6',
  '@lg': {
    gridTemplateColumns: '300px 1fr auto',
    gridTemplateRows: '1fr',
  },
  variants: {
    isVisible: {
      true: {
        display: 'grid',
      },
      false: {
        display: 'none',
      },
    },
  },
})
const Category = styled('div', {
  '@lg': {
    display: 'none',
  },
})

const Title = styled('p', {
  lift_font: 'heading02',
  mb: '$6',
})
const Description = styled('div', {
  lift_font: 'body02',
  color: '$white_06',
})
const DatePlace = styled('div', {
  lift_font: 'body02',
  color: '$white_06',
  '@lg': {
    lift_font: 'heading03',
    gridTemplateRows: '1fr',
  },
})

export const EventInfo: FC<EventsLandingEventProps> = ({
  category,
  startDate,
  endDate,
  address,
  title,
  path,
  introduction,
  isVisible,
  withYear,
}) => {
  const { t } = useTranslation('events')
  const Event = () => (
    <Wrap isVisible={isVisible}>
      <Category>
        {category && (
          <StyledClickable as={'div'} appearance={'blur'} size={'small'}>
            {t(category)}
          </StyledClickable>
        )}
      </Category>
      <DatePlace>
        <div>{getDurationString(startDate, endDate, withYear)}</div>
        <div>
          {address?.addressLine1} {address?.addressLine2}
        </div>
      </DatePlace>
      <div>
        {title && <Title>{title}</Title>}
        {introduction && (
          <Description>
            <PortableText value={introduction} />
          </Description>
        )}
      </div>
      <div>
        {path && (
          <Link href={path} appearance={'solidWhite'} size="small">
            {t('viewEvent')}
          </Link>
        )}
      </div>
    </Wrap>
  )

  if (!path) {
    return <Event />
  }

  return (
    <Link href={path} appearance="block" tabIndex={-1}>
      <Event />
    </Link>
  )
}
