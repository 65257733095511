import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Link } from '@liftfoils/components'
import { MapPin } from '@liftfoils/models'

const Content = styled('div', {
  display: 'grid',
  width: '100%',
  gridColumnGap: '$7',
  gridRowGap: '$5',
  gridTemplateColumns: 'auto 1fr',
  gridAutoRows: 'min-content',
  gridTemplateAreas: `
     'addressHeader addressLine1'
     'addressHeader addressLine2'
     'addressHeader addressMain'
     'phoneHeader phone'
     'emailHeader email'
     'websiteHeader website'
    `,
})

const ColHeader = styled('span', {
  lift_font: 'body03',
  color: '$gray500',
})
const ColText = styled('span', {
  lift_font: 'body03',
  color: '$gray500',
  overflow: 'hidden',
  a: {
    borderBottom: '1px solid $gray500',
    cursor: 'pointer',
    '&:hover': {
      color: '$gray300',
    },
  },
})

type PinContentProps = {
  item: MapPin
  className?: string
}

export const PinContent: FC<PinContentProps> = ({ item, className }) => {
  return (
    <Content className={className}>
      {(item.addressLine1 || item.addressLine2) && (
        <ColHeader css={{ gridArea: 'addressHeader' }}>Address</ColHeader>
      )}

      {item.addressLine1 && (
        <ColText css={{ gridArea: 'addressLine1' }}>
          {item.addressLine1}
        </ColText>
      )}
      {item.addressLine2 && (
        <ColText css={{ gridArea: 'addressLine2' }}>
          {item.addressLine2}
        </ColText>
      )}

      {(item.country || item.state || item.city || item.zip) && (
        <ColText css={{ gridArea: 'addressMain' }}>
          {[item.country, item.state, item.city, item.zip]
            .filter(Boolean)
            .join(', ')}
        </ColText>
      )}

      {item.phone && (
        <>
          <ColHeader css={{ gridArea: 'phoneHeader' }}>Phone</ColHeader>
          <ColText css={{ gridArea: 'phone' }}>{item.phone}</ColText>
        </>
      )}

      {item.email && (
        <>
          <ColHeader css={{ gridArea: 'emailHeader' }}>Email</ColHeader>
          <ColText css={{ gridArea: 'email' }}>
            <Link href={`mailto:${item.email}`} target="_blank">
              {item.email}
            </Link>
          </ColText>
        </>
      )}

      {item.website && (
        <>
          <ColHeader css={{ gridArea: 'websiteHeader' }}>Website</ColHeader>
          <ColText css={{ gridArea: 'website' }}>
            <Link href={item.website} target="_blank">
              {item.website}
            </Link>
          </ColText>
        </>
      )}
    </Content>
  )
}
