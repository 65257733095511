import { FC } from 'react'
import { PageBuilderSection } from '@liftfoils/models'
import { SectionStoriesLanding } from './SectionStoriesLanding/SectionStoriesLanding'
import { SectionEditorialContent } from './SectionEditorialContent/SectionEditorialContent'
import { SectionProductHero } from './SectionProductHero/SectionProductHero'

import { SectionHero } from './SectionHero/SectionHero'
import { SectionEditorialHero } from './SectionEditorialHero/SectionEditorialHero'
import { SectionEventsLanding } from './SectionEventsLanding/SectionEventsLanding'
import { SectionProductConversionArea } from './SectionProductConversionArea/SectionProductConversionArea'

import {
  ShopifyData,
  ShopifyResolvedProductExtended,
} from '@liftfoils/shopify-sdk'

import dynamic from 'next/dynamic'

const DynamicSectionVideoStories = dynamic(() =>
  import('./SectionVideoStories/SectionVideoStories').then(
    (mod) => mod.SectionVideoStories,
  ),
)
const DynamicSectionTextWithFeatures = dynamic(() =>
  import('./SectionTextWithFeatures/SectionTextWithFeatures').then(
    (mod) => mod.SectionTextWithFeatures,
  ),
)
const DynamicSectionFeaturesSlider = dynamic(() =>
  import('./SectionFeaturesSlider/SectionFeaturesSlider').then(
    (mod) => mod.SectionFeaturesSlider,
  ),
)
const DynamicSectionFullscreenInfo = dynamic(() =>
  import('./SectionFullscreenInfo/SectionFullscreenInfo').then(
    (mod) => mod.SectionFullscreenInfo,
  ),
)
const DynamicSectionVideoPlaylist = dynamic(() =>
  import('./SectionVideoPlaylist/SectionVideoPlaylist').then(
    (mod) => mod.SectionVideoPlaylist,
  ),
)
const DynamicSectionProductFeatures = dynamic(() =>
  import('./SectionProductFeatures/SectionProductFeatures').then(
    (mod) => mod.SectionProductFeatures,
  ),
)
const DynamicSectionProductSizes = dynamic(() =>
  import('./SectionProductSizes/SectionProductSizes').then(
    (mod) => mod.SectionProductSizes,
  ),
)
const DynamicSectionProductVideo = dynamic(() =>
  import('./SectionProductVideo/SectionProductVideo').then(
    (mod) => mod.SectionProductVideo,
  ),
)
const DynamicSectionPress = dynamic(() =>
  import('./SectionPress/SectionPress').then((mod) => mod.SectionPress),
)
const DynamicSectionMap = dynamic(() =>
  import('./SectionMap/SectionMap').then((mod) => mod.SectionMap),
)
const DynamicSectionYouTubePlayer = dynamic(() =>
  import('./SectionYouTubePlayer/SectionYouTubePlayer').then(
    (mod) => mod.SectionYouTubePlayer,
  ),
)
const DynamicSectionHugeImage = dynamic(() =>
  import('./SectionHugeImage/SectionHugeImage').then(
    (mod) => mod.SectionHugeImage,
  ),
)
const DynamicSectionUnequalCards = dynamic(() =>
  import('./SectionUnequalCards/SectionUnequalCards').then(
    (mod) => mod.SectionUnequalCards,
  ),
)
const DynamicSectionHalfImage = dynamic(() =>
  import('./SectionHalfImage/SectionHalfImage').then(
    (mod) => mod.SectionHalfImage,
  ),
)
const DynamicSectionCardsWithDescription = dynamic(() =>
  import('./SectionCardsWithDescription/SectionCardsWithDescription').then(
    (mod) => mod.SectionCardsWithDescriptions,
  ),
)
const DynamicSectionRelatedStories = dynamic(() =>
  import('./SectionRelatedStories/SectionRelatedStories').then(
    (mod) => mod.SectionRelatedStories,
  ),
)
const DynamicSectionShare = dynamic(() =>
  import('./SectionShare/SectionShare').then((mod) => mod.SectionShare),
)

const DynamicSectionRiderIntroduction = dynamic(() =>
  import('./SectionRiderIntroduction/SectionRiderIntroduction').then(
    (mod) => mod.SectionRiderIntroduction,
  ),
)
const DynamicSectionEventContent = dynamic(() =>
  import('./SectionEventContent/SectionEventContent').then(
    (mod) => mod.SectionEventContent,
  ),
)

const DynamicSectionCollection = dynamic(() =>
  import('./SectionCollection/SectionCollection').then(
    (mod) => mod.SectionCollection,
  ),
)

const DynamicSectionForms = dynamic(() =>
  import('./SectionForms/SectionForms').then((mod) => mod.SectionForms),
)

const DynamicSectionLeadershipMap = dynamic(() =>
  import('./SectionLeadershipMap/SectionLeadershipMap').then(
    (mod) => mod.SectionLeadershipMap,
  ),
)

const DynamicSectionCustomerSupport = dynamic(() =>
  import('./SectionCustomerSupport/SectionCustomerSupport').then(
    (mod) => mod.SectionCustomerSupport,
  ),
)

const DynamicSectionOrderSummary = dynamic(() =>
  import('./SectionOrderSummary/SectionOrderSummary').then(
    (mod) => mod.SectionOrderSummary,
  ),
)

const DynamicSectionRecommendedProducts = dynamic(() =>
  import('./SectionRecommendedProducts/SectionRecommendedProducts').then(
    (mod) => mod.SectionRecommendedProducts,
  ),
)

// @ts-ignore
const DynamicSectionWithFaq = dynamic(() =>
  import('./SectionWithFaq/SectionWithFaq').then((mod) => mod.SectionWithFaq),
)

export type PageBuilderProps = {
  section: PageBuilderSection
  shopifyData: ShopifyData
  shopifyProduct?: ShopifyResolvedProductExtended
}

export const PageBuilder: FC<PageBuilderProps> = ({
  section,
  shopifyData,
  shopifyProduct,
}) => {
  switch (section._type) {
    case 'sectionHero':
      return <SectionHero {...section} />
    case 'sectionProductHero':
      return <SectionProductHero {...section} />
    case 'sectionStoriesLanding':
      return <SectionStoriesLanding {...section} />
    case 'sectionEditorialContent':
      return <SectionEditorialContent {...section} shopifyData={shopifyData} />
    case 'sectionEventsLanding':
      return <SectionEventsLanding {...section} />
    case 'sectionEditorialHero':
      return <SectionEditorialHero {...section} />
    case 'sectionProductConversionArea':
      return (
        <SectionProductConversionArea
          {...section}
          shopifyData={shopifyData}
          shopifyProduct={shopifyProduct}
        />
      )

    // Sections below are dynamic imported to optimize chunks delivered to user.
    case 'sectionVideoStories':
      return <DynamicSectionVideoStories {...section} />
    case 'sectionTextWithFeatures':
      return <DynamicSectionTextWithFeatures {...section} />
    case 'sectionProductFeatures':
      return <DynamicSectionProductFeatures {...section} />
    case 'sectionProductVideo':
      return <DynamicSectionProductVideo {...section} />
    case 'sectionProductSizes':
      return (
        <DynamicSectionProductSizes {...section} shopifyData={shopifyData} />
      )
    case 'sectionFeaturesSlider':
      return <DynamicSectionFeaturesSlider {...section} />
    case 'sectionFullscreenInfo':
      return <DynamicSectionFullscreenInfo {...section} />
    case 'sectionVideoPlaylist':
      return <DynamicSectionVideoPlaylist {...section} />
    case 'sectionPress':
      return <DynamicSectionPress {...section} />
    case 'sectionMap':
      return <DynamicSectionMap {...section} />
    case 'sectionYouTubePlayer':
      return <DynamicSectionYouTubePlayer {...section} />
    case 'sectionCardsWithDescription':
      return <DynamicSectionCardsWithDescription {...section} />
    case 'sectionHugeImage':
      return <DynamicSectionHugeImage {...section} />
    case 'sectionUnequalCards':
      return <DynamicSectionUnequalCards {...section} />
    case 'sectionHalfImage':
      return <DynamicSectionHalfImage {...section} />
    case 'sectionRelatedStories':
      return <DynamicSectionRelatedStories {...section} />
    case 'sectionShare':
      return <DynamicSectionShare {...section} />
    case 'sectionRiderIntroduction':
      return <DynamicSectionRiderIntroduction {...section} />
    case 'sectionCollection':
      return <DynamicSectionCollection {...section} shopifyData={shopifyData} />
    case 'sectionCustomerSupport':
      return <DynamicSectionCustomerSupport {...section} />
    case 'sectionEventContent':
      return <DynamicSectionEventContent {...section} />
    case 'sectionForms':
      return <DynamicSectionForms {...section} />
    case 'sectionLeadershipMap':
      return <DynamicSectionLeadershipMap {...section} />
    case 'sectionWithFaq':
      return <DynamicSectionWithFaq {...section} />
    case 'sectionOrderSummary':
      return <DynamicSectionOrderSummary {...section} />
    case 'sectionRecommendedProducts':
      return (
        <DynamicSectionRecommendedProducts
          {...section}
          productId={shopifyProduct?.id}
        />
      )
    default:
      //throw new Error(`Section ${props.__typename} can't be rendered`)
      return null
  }
}
