import { FC, Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  MediaFieldType,
  SectionProductConversionAreaProps,
} from '@liftfoils/models'
import { styled } from '@liftfoils/styles'
import {
  ArrowDownSlim,
  GridIcon,
  View360Icon,
  ViewAnatomyIcon,
} from '@liftfoils/icons'
import smoothscroll from 'smoothscroll-polyfill'
import {
  Anatomy,
  Button,
  ContextualizedProductPrice,
  ContextualizedProductVariantPrice,
  Link,
  Modal,
  PortableText,
  seoImageLoader,
} from '@liftfoils/components'
import { GearSelectorModal } from '@liftfoils/gear-selector'
import { useTranslation } from 'react-i18next'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import {
  OptionValueProps,
  ProductOptionProps,
} from './components/ProductOption'
import { NextSeo, ProductJsonLd } from 'next-seo'
import { WatchInMotion } from './components/WatchInMotion'
import { GTMViewItem } from '@liftfoils/services/gtm-service'
import { AddBundleToCartButton, AddToCartButton } from '@liftfoils/cart'
import { GRADIENT_BG } from './config'
import { ProductGallery } from './components/ProductGallery'
import { ReviewsBadge } from './components/ReviewsBagde'
import { __getVariantBySelectedOptions } from './helpers/__getVariantBySelectedOptions'

import { ShopifyResolvedVariantBasic } from '@liftfoils/shopify-sdk'
import { OptionsArea } from './components/OptionsArea'
import { SendProductConfigModal } from './components/SendProductConfigModal'
import { useGearSelectorContext } from '@liftfoils/gear-selector-provider'
import {
  BUNDLE_HANDLE_KEY,
  BUNDLE_TITLE_KEY,
  EXCLUDED_LOCALES,
} from '@liftfoils/configs'
import { getWeight } from './helpers/getWeight'
import { subtractShopifyPrice } from './helpers/subtractShopifyPrice'
import { useAccount } from '@liftfoils/services/shopify-service'
import MediaGallery from './components/MediaGallery'

const QUANTITY_OPTIONS_NUMBER = 5

const Wrap = styled('div', {
  bg: GRADIENT_BG,
})

const Badge = styled('div', {
  color: '$teal500',
  lift_font: 'body03',
  gridArea: 'tag',
})

const MainGrid = styled('div', {
  display: 'grid',
  pt: '$headerHeight',
  alignItems: 'start',
  gridTemplateAreas: `
                      'gallery'
                      'meta'
                      'options-area'
                      'buy-area'
                      'details'
                      `,
  '@lg': {
    minHeight: '100vh',

    ml: '$8',
    mr: '$containerMargin',
    gridGap: '$10',
    gridTemplateRows: 'auto auto auto 1fr',
    gridTemplateColumns: '2fr minmax(400px, 1fr)',
    gridTemplateAreas: `
                        'gallery meta'
                        'gallery details'
                        'gallery options-area'
                        'gallery buy-area'
                        `,
  },
  '@xl': {
    gridTemplateColumns: '2fr minmax(480px, 1fr)',
  },
})
const ProductMeta = styled('div', {
  gridArea: 'meta',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  rowGap: '$4',
  columnGap: '$6',
  px: '$containerMargin',
  py: '$10',
  background: 'white',
  gridTemplateAreas: `
      'tag tag'
      'title price'
      'disclaimer disclaimer'
      'stars stars'
    `,
  '@lg': {
    px: '0',
    py: '0',
    pt: '$headerHeight',
    background: 'none',
    gridTemplateAreas: `
      'tag tag'
      'title stars'
      'price price'
      'disclaimer disclaimer'
    `,
    gridTemplateColumns: '1fr',
  },
})

const ProductTitle = styled('h1', {
  lift_font: 'caps05',
  color: '$gray500',
  gridArea: 'title',
})

const PriceWrap = styled('div', {
  lift_font: 'body02',
  color: '$gray500_06',
  s: {
    color: '$gray300',
  },
  gridArea: 'price',
})

const PriceDisclaimer = styled('div', {
  lift_font: 'body06',
  color: '$gray500_06',
  s: {
    color: '$gray300',
  },
  gridArea: 'disclaimer',
  '@sm': {
    textAlign: 'right',
  },
  '@lg': {
    textAlign: 'left',
  },
})
const StarsWrap = styled('div', {
  gridArea: 'stars',
})

const ProductDetails = styled('div', {
  gridArea: 'details',
  px: '$containerMargin',
  py: '$10',
  '@lg': {
    px: '0',
    py: '0',
  },
})

const Description = styled('div', {
  lift_font: 'body02',
  color: '$gray300',
  '.highlighted': {
    color: '$gray500',
  },
  '.superscript': {
    fontSize: 'small',
    verticalAlign: 'super',
  },
  '& a': {
    textDecoration: 'underline',
  },
  'p + p, div + p, .OutlineElement + .OutlineElement': {
    mt: '1em',
  },
  'ol, ul': {
    listStyle: 'outside',
    paddingLeft: '$8',

    li: {
      mt: '$3',
    },
  },
  'ul > li': {
    listStyleType: 'disc',
  },
})

const ButtonLabel = styled('span', {
  display: 'flex',
  alignItems: 'center',

  '& svg': {
    marginRight: '$5',
  },
})

const ImportantFeaturesWrap = styled('div', {
  display: 'grid',
  borderTop: '1px solid $gray500_01',
  borderBottom: '1px solid $gray500_01',
  py: '$8',
  mt: '$8',
  rowGap: '$6',
  columnGap: '$8',
  lift_font: 'body05',
  gridTemplateColumns: 'repeat( auto-fill, minmax(150px, auto) )',
  '@sm': {
    gridTemplateColumns: 'repeat( 2, minmax(150px, auto) )',
  },
  '@lg': {
    gridTemplateColumns: 'repeat( 2, minmax(150px, max-content) )',
  },
})

const ImportantFeature = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
})

const FeatureKey = styled('div', {
  color: '$gray300',
  mr: '$4',

  whiteSpace: 'nowrap',
  width: 'max-content',
})

const FeatureValue = styled('div', {
  color: '$gray500',
  overflow: 'hidden',
  '-webkit-line-clamp': 1,
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
  maxWidth: '200px',
  textOverflow: 'ellipsis',
  width: 'max-content',

  '@sm': {
    maxWidth: '250px',
  },
})

const Parameters = styled('div', {
  borderTop: '1px solid $gray100',
  borderBottom: '1px solid $gray100',
  py: '$8',
  display: 'none',
  gridTemplateColumns: '1r 1fr 1fr',
  gridGap: '$5',
  lift_font: 'heading03',
})

const Parameter = styled('div', {
  'span:first-child': {
    color: '$gray300',
    mr: '$4',
  },
  'span:last-child': {
    color: '$gray500',
  },
})

const BuyArea = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: '$5',
  gridArea: 'buy-area',
  bg: 'white',
  px: '$containerMargin',
  pb: '$8',
  '@lg': {
    bg: 'none',
    px: '0',
  },
  variants: {
    withWatchInMotion: {
      false: {},
    },
  },
})

const AdditionalMessage = styled('span', {
  display: 'grid',
  lift_font: 'body02',
  color: '$gray500',
  marginBottom: '$2',
})

const BuyTriggers = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: '$5',
  '@lg': {
    gridTemplateColumns: 'auto 1fr',
  },
})
const BottomText = styled('div', {
  lift_font: 'body02',
  color: '$gray300',
  mt: '$8',
  '.highlighted': {
    color: '$gray500',
  },
  'a, button': {
    lift_font: 'body02',
    textDecoration: 'underline',
    transition: 'color 100ms',
    '&:hover': {
      color: '$teal500',
    },
  },
})
const QuantityButton = styled(Button, {
  'button&': {
    px: '$8',
    borderColor: '$gray200',
  },
  fill: '$gray300',
})

const QuantityButtonLabel = styled('div', {
  mr: '$6',
  '& > span:first-child': {
    color: '$gray300',
    ml: 0,
    mr: '$5',
  },
  '& > span:last-child': {
    color: '$gray500',
    m: 0,
  },
})

const QuantityModalButtons = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '$14',
  borderRadius: '$r1',
  overflow: 'hidden',
})

const QuantityModalButton = styled(Button, {
  borderBottom: '1px solid $gray100',
  borderRadius: '0 !important',
  '&:last-of-type': {
    borderBottom: 'none',
  },
})

const ProductImageButtonWrap = styled('div', {
  $hasContainerMX: true,
  width: 'auto',
  display: 'grid',
  gridTemplateRow: 1,
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  gridRow: 1,
  gridColumn: 1,
  alignSelf: 'end',
  alignItems: 'end',
  justifySelf: 'start',
  justifyContent: 'start',
  pb: '$8',
  zIndex: 3,

  '@lg': {
    width: 'auto',
    gridGap: '$6',
    mx: '0',
    position: 'sticky',
    bottom: 0,
    left: 0,
    gridRow: 4,
    pb: '$9',
  },
})

const DesktopVideoWrap = styled('div', {
  display: 'none',
  '@lg': {
    display: 'grid',
  },
})

const MobileVideoWrap = styled('div', {
  alignSelf: 'end',
  display: 'grid',
  mx: '$containerMargin',
  pt: '$10',
  '@lg': {
    display: 'none',
  },
})

const GearSelectorButton = styled(Button, {
  padding: '$6 $8',
  mt: '$8',
})
const MobileAnatomyButtonWrap = styled('div', {
  mb: '$10',
  mt: '$6',
  mx: '$containerMargin',
  '@lg': {
    display: 'none',
  },
})

const DesktopAnatomyButtonWrap = styled('div', {
  display: 'none',

  '@lg': {
    display: 'block',
  },
})

const MobileAnatomyButton = styled(Button, {
  width: '100%',
})

type ProductPageType =
  | 'SINGLE_VARIANT'
  | 'EFOIL'
  | 'SINGLE_OPTION'
  | 'MULTIPLE_OPTIONS'
type Attribute = { key: string; value: string }
const B2BDisclaimer = () => {
  const { isB2B } = useAccount()
  if (!isB2B) return null
  return (
    <div>
      Final wholesale pricing is determined by the volume on your total order.
    </div>
  )
}
export const SectionProductConversionArea: FC<
  SectionProductConversionAreaProps
> = ({
  shopifyData,
  shopifyProduct,
  additionalDescription,
  video,
  kits,
  extraMedia,
  productPageConfig,
  overwrittenProductOptions,
  dangerouslyDisabled,
  anatomy,
  importantFeatures,
  gearSelectorType,
  enrichedProductOptions,
}) => {
  const [activeProductMediaIndex, setActiveProductMediaIndex] = useState(0)
  if (!shopifyProduct) {
    throw new Error('Missing shopifyProduct')
  }

  if (!shopifyData) {
    throw new Error('Missing shopifyData')
  }

  const allShopifyVariants = (() => {
    const variantsFromBasicProducts = Object.values(
      shopifyData.productBasic,
    ).reduce<ShopifyResolvedVariantBasic[]>((acc, product) => {
      return [...acc, ...product.variants.nodes]
    }, [])

    return [
      ...(shopifyData.variantBasic && Object.values(shopifyData.variantBasic)),
      ...variantsFromBasicProducts,
    ]
  })()

  const __getVariantById = (id: string) => {
    return allShopifyVariants.find((v) => v.id === id)
  }

  const showAdditionalMessage =
    shopifyProduct.metafields.show_additional_message
  const additionalMessage = shopifyProduct.metafields.additional_message

  const { locale, replace, query, asPath } = useRouter()
  const { t } = useTranslation('product')
  const { t: tGearSelector } = useTranslation('gearSelector')
  const fullUrl = `${process.env.BASE_URL}${asPath}`

  const [quantity, setQuantity] = useState<number | undefined>(1)
  const [isQuantityModalOpen, setQuantityModalOpen] = useState(false)
  const [isAnatomyModalOpen, setIsAnatomyModalOpen] = useState(false)
  const [isSendProductConfigModalOpen, setSendProductConfigModalOpen] =
    useState(false)
  const [activeGallerySection, setActiveGallerySection] = useState<
    'photo' | 'model3d'
  >('photo')
  const { openModal } = useGearSelectorContext()

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const pageType: ProductPageType = (() => {
    if (shopifyProduct.productType === 'efoil-main') return 'EFOIL'
    if (shopifyProduct.variants.nodes.length === 1) return 'SINGLE_VARIANT'
    if (shopifyProduct.options.length === 1) return 'SINGLE_OPTION'
    if (shopifyProduct.options.length > 1) return 'MULTIPLE_OPTIONS'
    throw new Error('Wrong ProductPageType')
  })()

  // const selectedVariantId = query['variantId']

  const selectedEfoilOptionsFromQuery = (() => {
    if (pageType === 'EFOIL') {
      return overwrittenProductOptions?.reduce<
        { name: string; value: string }[]
      >((acc, val) => {
        const { name, _key, values } = val

        const valKeyFromQuery = query[_key]

        if (!(valKeyFromQuery && name)) return acc

        const value = values?.find((v) => v._key === valKeyFromQuery)?.title
        if (!value) return acc

        return [...acc, { name, value }]
      }, [])
    }
    return undefined
  })()

  const selectedVariant = (() => {
    // product with only one variant
    if (pageType === 'SINGLE_VARIANT') {
      return shopifyProduct.variants.nodes[0]
    }
    // product with multiple variants and one option
    // if (
    //   pageType === 'SINGLE_OPTION' &&
    //   selectedVariantId &&
    //   typeof selectedVariantId === 'string'
    // ) {
    //   const variant = shopifyProduct.variants.nodes.find(
    //     (v) => v.id === selectedVariantId,
    //   )
    //   return variant ?? undefined
    // }

    if (pageType === 'MULTIPLE_OPTIONS' || pageType === 'SINGLE_OPTION') {
      return shopifyProduct.variants.nodes.find((v) => {
        return v.selectedOptions.every((option) => {
          return query[option.name] === option.value
        })
      })
    }

    if (pageType === 'EFOIL') {
      const mainKit = kits?.find((kit) => kit.isMain)
      if (!mainKit) {
        throw new Error('Main kit not found')
      }
      const mainKitProduct = shopifyData.productBasic?.[mainKit.productId]

      const mainKitVariants = mainKitProduct?.variants.nodes

      const mainKitOptions = mainKitProduct?.options.map(
        (option) => option.name,
      )

      const mainKitSelectedOptions = selectedEfoilOptionsFromQuery?.filter(
        (o) => mainKitOptions?.includes(o.name),
      )
      if (!mainKitSelectedOptions) return undefined

      return mainKitOptions?.length === mainKitSelectedOptions.length &&
        mainKitVariants
        ? __getVariantBySelectedOptions(mainKitVariants, mainKitSelectedOptions)
        : undefined
    }

    return undefined
  })()

  const allShopifyEfoilKitsOptions = (() => {
    return kits?.reduce<{ name: string; values: string[] }[]>((acc, kit) => {
      const product = shopifyData.productBasic?.[kit.productId]
      if (!product) throw new Error('Product not found')
      return [
        ...acc,
        ...product.options.map((o) => ({
          name: o.name,
          values: o.values,
        })),
      ]
    }, [])
  })()

  const optionsToSelect: ProductOptionProps[] = (() => {
    if (pageType === 'EFOIL') {
      const sanityOptions =
        overwrittenProductOptions?.reduce<ProductOptionProps[]>(
          (acc, sanityOption) => {
            const {
              _key: optionId,
              name: optionNameFromShopify,
              showPrice,
              wideElements,
              overwrittenName,
              guidanceModalLabel,
              showGuidanceModal,
              expandAllValues,
            } = sanityOption

            const currShopifyKitOption = allShopifyEfoilKitsOptions?.find(
              (o) => o.name === optionNameFromShopify,
            )

            if (!currShopifyKitOption) return acc

            const valuePrices = (() => {
              if (!showPrice) return undefined
              let cheapestVariant: ShopifyResolvedVariantBasic | undefined

              const variantsWithCurrentOption = allShopifyVariants?.filter(
                (v) => {
                  return v.selectedOptions.find(
                    (o) => o.name === optionNameFromShopify,
                  )
                },
              )

              variantsWithCurrentOption?.forEach((v) => {
                if (
                  !cheapestVariant ||
                  (cheapestVariant &&
                    parseFloat(cheapestVariant.price.amount) >
                      parseFloat(v.price.amount))
                ) {
                  cheapestVariant = v
                }
              })

              const cheapestVariantSelectedOptionsWithoutCurrentOption =
                cheapestVariant?.selectedOptions.filter(
                  (o) => o.name !== optionNameFromShopify,
                )
              if (!cheapestVariantSelectedOptionsWithoutCurrentOption) {
                return undefined
              }
              const variantsWithCurrentOptionWithCheapestOtherOptions =
                variantsWithCurrentOption?.filter((variant) =>
                  cheapestVariantSelectedOptionsWithoutCurrentOption.every(
                    (option) =>
                      variant.selectedOptions.find(
                        (o) => o.name === option.name,
                      )?.value === option.value,
                  ),
                )
              return sanityOption.values?.map((val) => {
                const price =
                  variantsWithCurrentOptionWithCheapestOtherOptions?.find((v) =>
                    v.selectedOptions.some((o) => o.value === val.title),
                  )?.price
                if (!(cheapestVariant && price)) {
                  throw new Error('Prices not found')
                }
                return {
                  title: val.title,
                  price: subtractShopifyPrice(price, cheapestVariant.price),
                }
              })
            })()

            const values = sanityOption.values?.reduce<OptionValueProps[]>(
              (acc, v) => {
                const optionValueId = v._key
                const { additionalDescription, showNotifyMe } = v
                const title = v.overwrittenTitle ?? v.title ?? 'Untitled value'

                const additionalVariant = v.additionalProductVariantId
                  ? __getVariantById(v.additionalProductVariantId)
                  : undefined

                if (!!v.additionalProductVariantId && !additionalVariant)
                  return acc

                const currShopifyKitOptionValue =
                  currShopifyKitOption.values.find((_v) => _v === v.title)
                if (!currShopifyKitOptionValue) return acc

                const priceDifference = showPrice
                  ? valuePrices?.find((_v) => _v && _v.title === v.title)?.price
                  : undefined

                return [
                  ...acc,
                  {
                    optionId,
                    id: optionValueId,
                    title,
                    price:
                      additionalVariant?.price ??
                      (showPrice ? priceDifference : undefined),
                    image: v.image,
                    selected: optionValueId === query[optionId],
                    features: v.features,
                    additionalDescription,
                    showNotifyMe,
                  },
                ]
              },
              [],
            )

            const title =
              overwrittenName ?? optionNameFromShopify ?? 'Untitled option'

            return [
              ...acc,
              {
                id: optionId,
                title,
                values: values ?? [],
                expandAllValues,
                wideElements,
                guidanceModalLabel,
                showGuidanceModal,
              },
            ]
          },
          [],
        ) ?? []

      return sanityOptions
    }
    if (pageType === 'SINGLE_VARIANT') {
      return []
    }
    return shopifyProduct.options.map((option) => {
      const optionName = option.name
      const currentOptionValue = query[option.name]

      const enrichedOption = enrichedProductOptions?.find(
        ({ shopifyReference }) => shopifyReference === optionName,
      )

      const {
        expandAllValues,
        guidanceModalLabel,
        showGuidanceModal,
        values: enrichedValues,
        wideElements,
      } = enrichedOption || {}

      const values = option.values.map((value) => {
        const getEnrichedValue = enrichedValues?.find(
          (enrichedValue) => enrichedValue.title === value,
        )

        if (getEnrichedValue) {
          const { title, additionalDescription, features, image } =
            getEnrichedValue
          return {
            id: title,
            title,
            optionId: `${option.name}`,
            selected: currentOptionValue === title,
            additionalDescription,
            features,
            image,
          }
        }

        return {
          id: value,
          title: value,
          optionId: `${option.name}`,
          selected: currentOptionValue === value,
        }
      })

      return {
        id: `variantId-${option.name}`,
        title: option.name,
        expandAllValues,
        guidanceModalLabel,
        showGuidanceModal,
        wideElements,
        values,
      }
    })
  })()

  const selectedAdditionalVariants: ShopifyResolvedVariantBasic[] | undefined =
    (() => {
      if (
        pageType === 'EFOIL' &&
        selectedEfoilOptionsFromQuery?.length ===
          allShopifyEfoilKitsOptions?.length
      ) {
        const extraVariants =
          overwrittenProductOptions?.reduce<ShopifyResolvedVariantBasic[]>(
            (acc, option) => {
              const currOptionSelectedValueKey = query[option._key]

              if (!currOptionSelectedValueKey) return acc

              const currOptionSelectedValueExtraVariantId = option.values?.find(
                (v) => v._key === currOptionSelectedValueKey,
              )?.additionalProductVariantId

              if (!currOptionSelectedValueExtraVariantId) return acc
              const variant = __getVariantById(
                currOptionSelectedValueExtraVariantId,
              )

              if (!variant) return acc

              return [...acc, variant]
            },
            [],
          ) ?? []

        const additionalVariants =
          kits?.reduce<ShopifyResolvedVariantBasic[]>((acc, kit) => {
            if (!kit) return acc

            if (!kit.isMain) {
              const groupSelectedOptions =
                selectedEfoilOptionsFromQuery?.filter(
                  (o) =>
                    shopifyData.productBasic &&
                    shopifyData.productBasic[kit.productId].options.find(
                      (_o) => _o.name === o.name,
                    ),
                )

              const variant = __getVariantBySelectedOptions(
                allShopifyVariants,
                groupSelectedOptions,
              )

              return variant ? [...acc, variant] : acc
            }

            return acc
          }, []) ?? []

        return [...extraVariants, ...additionalVariants]
      }
      return undefined
    })()

  const bundleAttributes: Attribute[] | undefined = (() => {
    if (pageType === 'EFOIL') {
      return overwrittenProductOptions?.reduce<Attribute[]>(
        (acc, option) => {
          const currOptionSelectedValueKey = query[option._key]
          if (!currOptionSelectedValueKey) return acc
          const currOptionSelectedValue = option.values?.find(
            (v) => v._key === currOptionSelectedValueKey,
          )
          if (!currOptionSelectedValue) return acc
          return [
            ...acc,
            {
              key: option.overwrittenName ?? option.name ?? 'Untitled option',
              value:
                currOptionSelectedValue.overwrittenTitle ??
                currOptionSelectedValue.title ??
                'Untitled value',
            },
          ]
        },
        [
          { key: BUNDLE_TITLE_KEY, value: shopifyProduct.title },
          { key: BUNDLE_HANDLE_KEY, value: shopifyProduct.handle },
        ],
      )
    }
    return undefined
  })()

  const [currentProductImages, current3DModelUUID] = ((): [
    MediaFieldType[] | undefined,
    string | undefined,
  ] => {
    if (pageType === 'EFOIL' && extraMedia && selectedEfoilOptionsFromQuery) {
      const selectedBoardSize =
        selectedEfoilOptionsFromQuery.find((o) => o.name === 'Board Size')
          ?.value ?? extraMedia[0].boardSize
      const selectedBoardFinish =
        selectedEfoilOptionsFromQuery.find((o) => o.name === 'Board Finish')
          ?.value ?? extraMedia[0].boardFinish
      const foundExtraMedia = extraMedia.find(
        (val) =>
          val.boardSize === selectedBoardSize &&
          val.boardFinish === selectedBoardFinish,
      )
      return [foundExtraMedia?.items ?? [], foundExtraMedia?.model3dUUID]
    }

    const imagesAsMedia: MediaFieldType[] | undefined =
      shopifyProduct.images?.map((image) => ({
        mediaPayload: image,
      }))

    return [imagesAsMedia, undefined]
  })()

  const onOptionChange = (optionId: string, groupId: string) => {
    replace(
      {
        query: {
          ...query,
          [groupId]: optionId,
        },
      },
      undefined,
      {
        shallow: true,
      },
    )
    const selectedOption = shopifyProduct.variants.nodes
      .flatMap((o) => ({ id: o.id, image: o.image }))
      .find((o) => o.id === optionId)
    const image = selectedOption?.image
    if (!image) return

    const imageIndex = shopifyProduct.images?.findIndex((img) => {
      if (image.type === 'shopify-image') {
        return img.image.src === image.image.src
      }
      return false
    })

    if (imageIndex !== undefined && imageIndex !== -1) {
      setActiveProductMediaIndex(imageIndex)
    }
  }
  const router = useRouter()

  const isCurrentRegionExcluded =
    router.locale && EXCLUDED_LOCALES.includes(router.locale)

  useEffect(() => {
    GTMViewItem(shopifyProduct, selectedVariant)
  }, [shopifyProduct, selectedVariant, router])
  const [localeId, regionId] = getLocaleRegionIdFromPath(locale)

  const handleQuantityClick = () => {
    setQuantityModalOpen(true)
  }

  const handleQuantityModalRequestClose = () => {
    setQuantityModalOpen(false)
  }
  const handleAnatomyModalRequestClose = () => {
    setIsAnatomyModalOpen(false)
  }

  const handleQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity)
    setQuantityModalOpen(false)
  }

  const selectedSeoImage: MediaFieldType | undefined = (() => {
    if (shopifyProduct.featuredImage) {
      return { mediaPayload: shopifyProduct.featuredImage }
    }
    if (shopifyProduct.images && shopifyProduct.images.length > 0) {
      return { mediaPayload: shopifyProduct.images[0] }
    }
    return undefined
  })()

  const seoImage = seoImageLoader(selectedSeoImage)

  const watchInMotionUrl = video?.youtubeVideoUrl

  useEffect(() => {
    if (!current3DModelUUID) {
      setActiveGallerySection('photo')
    }
  }, [current3DModelUUID])

  return (
    <Wrap>
      <ProductJsonLd
        productName={shopifyProduct?.title}
        images={[...(seoImage ? [seoImage.url] : [])]}
        description={shopifyProduct?.description}
        offers={[
          {
            price: shopifyProduct.priceRange.minVariantPrice.amount,
            priceCurrency:
              shopifyProduct.priceRange.minVariantPrice.currencyCode,
            itemCondition: 'https://schema.org/NewCondition',
            availability: 'https://schema.org/InStock',
            url: fullUrl,
            seller: {
              name: 'Lift Foils',
            },
          },
        ]}
        brand="Lift Foils"
      />
      <NextSeo
        title={shopifyProduct?.seo.title ?? shopifyProduct?.title}
        description={
          shopifyProduct?.seo.description ?? shopifyProduct.description
        }
        openGraph={{
          url: fullUrl,
          type: 'website',
          title: shopifyProduct?.seo.title ?? undefined,
          description: shopifyProduct?.seo.description ?? undefined,
          images: [...(seoImage ? [seoImage] : [])],
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
        noindex={dangerouslyDisabled}
        nofollow={dangerouslyDisabled}
      />
      <MainGrid>
        {pageType === 'EFOIL' ? (
          currentProductImages && (
            <ProductGallery
              images={currentProductImages}
              activeGallerySection={activeGallerySection}
              current3DModelUUID={current3DModelUUID}
              activeProductMediaIndex={activeProductMediaIndex}
              setActiveProductMediaIndex={setActiveProductMediaIndex}
            />
          )
        ) : (
          <MediaGallery
            variant={selectedVariant}
            productImages={currentProductImages}
            activeGallerySection={activeGallerySection}
            current3DModelUUID={current3DModelUUID}
          />
        )}
        <ProductMeta>
          {shopifyProduct.tags.includes('new') && <Badge>{t('new')}</Badge>}
          <ProductTitle>{shopifyProduct.title}</ProductTitle>
          <PriceWrap>
            {shopifyProduct.priceRange.maxVariantPrice.amount !==
            shopifyProduct.priceRange.minVariantPrice.amount ? (
              <ContextualizedProductPrice
                priceRange={shopifyProduct.priceRange}
                productId={shopifyProduct.id}
              />
            ) : (
              <ContextualizedProductVariantPrice
                price={shopifyProduct.variants.nodes[0].price}
                compareAtPrice={
                  shopifyProduct.variants.nodes[0].compareAtPrice ?? undefined
                }
                productVariantId={shopifyProduct.variants.nodes[0].id}
              />
            )}
          </PriceWrap>
          {productPageConfig && productPageConfig.priceDisclaimer && (
            <PriceDisclaimer>
              <B2BDisclaimer />
              {productPageConfig.priceDisclaimer}
            </PriceDisclaimer>
          )}

          <StarsWrap>
            {shopifyProduct.metafields.rating &&
              shopifyProduct.metafields.ratingCount && (
                <ReviewsBadge
                  rating={shopifyProduct.metafields.rating}
                  ratingCount={shopifyProduct.metafields.ratingCount}
                />
              )}
          </StarsWrap>
        </ProductMeta>
        <ProductDetails>
          <Description>
            <div
              dangerouslySetInnerHTML={{
                __html: shopifyProduct.descriptionHtml,
              }}
            />
            <PortableText value={additionalDescription} />
          </Description>
          {gearSelectorType && (
            <>
              <GearSelectorButton
                appearance={'solidGray500'}
                onClick={openModal}
              >
                {tGearSelector('findYourConfiguration')}
              </GearSelectorButton>
              <GearSelectorModal gearSelectorType={gearSelectorType} />
            </>
          )}
          {importantFeatures && (
            <ImportantFeaturesWrap>
              {importantFeatures.map(({ key, value }, index) => (
                <Fragment key={index}>
                  {key && value && (
                    <ImportantFeature>
                      <FeatureKey>{key}</FeatureKey>
                      <FeatureValue title={value}>{value}</FeatureValue>
                    </ImportantFeature>
                  )}
                </Fragment>
              ))}
            </ImportantFeaturesWrap>
          )}
          <Parameters>
            {selectedVariant?.weight && (
              <Parameter>
                <span>{t('weight')}</span>
                <span>
                  {getWeight(
                    selectedVariant.weight,
                    selectedVariant.weightUnit,
                    localeId,
                    regionId,
                  )}
                </span>
              </Parameter>
            )}
          </Parameters>
        </ProductDetails>

        {optionsToSelect?.length > 0 && (
          <OptionsArea
            productTitle={shopifyProduct?.title}
            onOptionChange={onOptionChange}
            optionsToSelect={optionsToSelect}
            shopifyVariants={allShopifyVariants}
          />
        )}

        <BuyArea withWatchInMotion={!!watchInMotionUrl}>
          {showAdditionalMessage && additionalMessage && (
            <AdditionalMessage>{additionalMessage}</AdditionalMessage>
          )}
          {isCurrentRegionExcluded ? (
            pageType === 'EFOIL' ? (
              <AddBundleToCartButton
                attributes={bundleAttributes}
                baseProductVariant={selectedVariant}
                additionalProductVariants={selectedAdditionalVariants}
              />
            ) : (
              <BuyTriggers>
                <QuantityButton
                  appearance={'outlineGray300Alt'}
                  size={'large'}
                  onClick={handleQuantityClick}
                >
                  <QuantityButtonLabel>
                    <span>{t('quantity')}</span>
                    <span>{quantity}</span>
                  </QuantityButtonLabel>
                  <ArrowDownSlim />
                </QuantityButton>
                <AddToCartButton
                  attributes={bundleAttributes}
                  productVariant={selectedVariant}
                  quantity={quantity}
                />
              </BuyTriggers>
            )
          ) : (
            <>
              {pageType === 'EFOIL' ? (
                <Button
                  appearance={'solidTeal500'}
                  size={'large'}
                  onClick={() => setSendProductConfigModalOpen(true)}
                >
                  Send config to Partner
                </Button>
              ) : (
                <Link
                  href={'/contact'}
                  size={'medium'}
                  appearance={'solidTeal500'}
                >
                  <span>Find Partner</span>
                </Link>
              )}
            </>
          )}
          <BottomText>
            <PortableText value={productPageConfig?.additionalText} />
          </BottomText>
        </BuyArea>
        {watchInMotionUrl && (
          <MobileVideoWrap>
            <WatchInMotion
              title={video?.title}
              youtubeVideoUrl={watchInMotionUrl}
              thumbnail={video?.thumbnail}
            />
          </MobileVideoWrap>
        )}
        {anatomy && anatomy.showAnatomy === true && (
          <MobileAnatomyButtonWrap>
            <MobileAnatomyButton
              appearance="solidWhite"
              size="medium"
              onClick={() => setIsAnatomyModalOpen(true)}
            >
              <ButtonLabel>
                <ViewAnatomyIcon />
                {t('viewAnatomy')}
              </ButtonLabel>
            </MobileAnatomyButton>
          </MobileAnatomyButtonWrap>
        )}
        <ProductImageButtonWrap>
          {watchInMotionUrl && (
            <DesktopVideoWrap>
              <WatchInMotion
                title={video?.title}
                youtubeVideoUrl={watchInMotionUrl}
                thumbnail={video?.thumbnail}
              />
            </DesktopVideoWrap>
          )}
          {current3DModelUUID &&
            (activeGallerySection === 'model3d' ? (
              <Button
                appearance="solidWhite"
                size="medium"
                onClick={() => setActiveGallerySection('photo')}
              >
                <ButtonLabel>
                  <GridIcon />
                  {t('viewPhotos')}
                </ButtonLabel>
              </Button>
            ) : (
              <Button
                appearance="solidWhite"
                size="medium"
                onClick={() => setActiveGallerySection('model3d')}
              >
                <ButtonLabel>
                  <View360Icon />
                  {t('view360')}
                </ButtonLabel>
              </Button>
            ))}
          {anatomy && anatomy.showAnatomy === true && (
            <DesktopAnatomyButtonWrap>
              <Button
                appearance="solidWhite"
                size="medium"
                onClick={() => setIsAnatomyModalOpen(true)}
              >
                <ButtonLabel>
                  <ViewAnatomyIcon />
                  {t('viewAnatomy')}
                </ButtonLabel>
              </Button>
            </DesktopAnatomyButtonWrap>
          )}
        </ProductImageButtonWrap>
      </MainGrid>
      <SendProductConfigModal
        isOpen={isSendProductConfigModalOpen}
        handleClose={() => setSendProductConfigModalOpen(false)}
        attributes={bundleAttributes}
      />
      <Modal
        isOpen={isQuantityModalOpen}
        onRequestClose={handleQuantityModalRequestClose}
        variant={'rounded'}
      >
        <QuantityModalButtons>
          {[...Array(QUANTITY_OPTIONS_NUMBER)].map((el, index) => {
            const quantity = index + 1
            return (
              <QuantityModalButton
                appearance="solidWhite"
                size="large"
                onClick={() => handleQuantityChange(quantity)}
                key={index}
              >
                {quantity}
              </QuantityModalButton>
            )
          })}
        </QuantityModalButtons>
      </Modal>
      <Modal
        isOpen={isAnatomyModalOpen}
        onRequestClose={handleAnatomyModalRequestClose}
        size="stretch"
      >
        <Anatomy {...anatomy} onClose={() => setIsAnatomyModalOpen(false)} />
      </Modal>
    </Wrap>
  )
}
