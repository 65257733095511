import {
  Button,
  Media,
  MediaPlaceholder,
  PortableText,
} from '@liftfoils/components'
import { OptionValueProps } from '../ProductOption'
import {
  Dispatch,
  Fragment,
  MutableRefObject,
  SetStateAction,
  useEffect,
} from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

const Root = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateRows: 'min-content 1fr',
  padding: '$8',

  '&:last-child': {
    borderRight: 'none',
  },

  '@md': {
    padding: '$8 40px',
  },
})

const Card = styled('div', {
  position: 'relative',
  display: 'grid',
  height: '100%',
  gridTemplateRows: 'auto min-content',
})

const Tracker = styled('div', {
  position: 'sticky',
  top: '$10',
  left: '0',
  width: '100%',
  height: '1px',
})

const CardHeading = styled('div', {
  marginTop: '$7',
  paddingBottom: '$4',
  borderBottom: '1px solid $gray500_01',
})

const CardTitleWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '$6',
  alignItems: 'center',
})

const CardTitle = styled('h3', {
  lift_font: 'body02',
  display: 'inline-block',
  marginRight: '$4',
  color: '$black',
})

const Features = styled('div', {
  lift_font: 'body02',
  color: '$gray400',
  display: 'inline',
})

const Description = styled('div', {
  margin: '$6 0 $9',
  lift_font: 'body02',
  color: '$gray300',
  '.highlighted': {
    color: '$gray500',
  },
  '.superscript': {
    fontSize: 'small',
    verticalAlign: 'super',
  },
  '& a': {
    textDecoration: 'underline',
  },
  'p + p, div + p': {
    mt: '1em',
  },
  'ol, ul': {
    listStyle: 'outside',
    paddingLeft: '$8',

    li: {
      mt: '$3',
    },
  },
  'ul > li': {
    listStyleType: 'disc',
  },
})

const ButtonWrap = styled('div', {
  width: 'max-content',
  justifySelf: 'end',
})

type CardSlideProps = {
  card: OptionValueProps
  handleClose: () => void
  onOptionChange: (optionId: string, groupId: string) => void
  slider?: MutableRefObject<HTMLDivElement | null>
  handleInView: Dispatch<SetStateAction<boolean>> | null
}

export const CardSlide = ({
  card,
  handleClose,
  onOptionChange,
  handleInView,
}: CardSlideProps) => {
  const { t } = useTranslation('guidanceModal')

  const [ref, isInView] = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (handleInView) {
      handleInView(isInView)
    }
  }, [isInView])

  const {
    title,
    image,
    features,
    optionId,
    id,
    additionalDescription,
    selected,
  } = card

  return (
    <Root>
      <Tracker ref={ref} />
      <Card>
        <span>
          <MediaPlaceholder ratio={'landscape3'}>
            <Media
              {...image}
              layout={'fill'}
              objectFit={'contain'}
              sizes={'(min-width: 770px) 300px, 230px'}
              alt={image?.alt ?? ''}
            />
          </MediaPlaceholder>
          <CardHeading>
            <CardTitleWrap>
              <Features>
                <CardTitle>{title}</CardTitle>

                {features &&
                  features.map((a, i) => (
                    <Fragment key={i}>
                      {a}
                      <br />
                    </Fragment>
                  ))}
              </Features>

              {onOptionChange && (
                <ButtonWrap>
                  <Button
                    appearance={'outlineTeal500'}
                    size={'small'}
                    disabled={selected}
                    onClick={() => {
                      onOptionChange(id, optionId)
                      handleClose()
                    }}
                  >
                    {selected ? t('selected') : t('select')}
                  </Button>
                </ButtonWrap>
              )}
            </CardTitleWrap>
          </CardHeading>

          <Description>
            <PortableText value={additionalDescription} />
          </Description>
        </span>
      </Card>
    </Root>
  )
}
