export const getWeight = (
  weight: number,
  weightUnit: string,
  localeId: string,
  regionId: string,
) => {
  const unit = (() => {
    switch (weightUnit) {
      case 'POUNDS': {
        return 'pound'
      }

      case 'KILOGRAMS': {
        return 'kilogram'
      }

      case 'OUNCES': {
        return 'ounce'
      }

      case 'GRAMS': {
        return 'gram'
      }

      default: {
        return weightUnit
      }
    }
  })()

  return new Intl.NumberFormat(`${localeId}-${regionId}`, {
    style: 'unit',
    unit,
  } as any).format(weight)
}
