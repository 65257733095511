import { FC, useState } from 'react'
import {
  Media,
  YouTubePlayer,
  VideoModalContentWrap,
  Modal,
  Button,
  MediaPlaceholder,
} from '@liftfoils/components'
import { styled } from '@liftfoils/styles'
import { getYouTubeIdFromUrl } from '@liftfoils/utils'
import { PlayIcon } from '@liftfoils/icons'
import { useTranslation } from 'react-i18next'
import { MediaFieldType } from '@liftfoils/models'

const ClickableWrap = styled(Button, {
  display: 'grid',
  borderRadius: '$r1',
  overflow: 'hidden',
  mb: '$8',
  '@lg': {
    maxWidth: '200px',
    mb: '0',
  },
  px: '$0',
  variants: {
    withThumbnail: {
      false: {
        borderRadius: '$rMax',
        minWidth: '100px',
        $px: '$9',
      },
    },
  },
})

const Text = styled('div', {
  height: '$buttonHeightM',
  lift_font: 'body03',
  color: '$gray500',
  $py: 'XS',
  px: '$8',
  display: 'flex',
  gap: '$3',
  alignItems: 'center',
  justifyContent: 'center',
  fill: '$gray300',
  background: 'white',
})

interface WatchInMotionProps {
  thumbnail?: MediaFieldType
  youtubeVideoUrl?: string
  title?: string
}

export const WatchInMotion: FC<WatchInMotionProps> = ({
  thumbnail,
  youtubeVideoUrl,
  title,
}) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false)
  const { t } = useTranslation('product')
  return (
    <>
      <ClickableWrap
        onClick={() => setVideoModalOpen(!isVideoModalOpen)}
        withThumbnail={!!thumbnail}
      >
        <MediaPlaceholder ratio={'landscape2'}>
          {thumbnail && (
            <Media
              {...thumbnail}
              hardcropRatio={'landscape2'}
              layout="fill"
              objectFit="cover"
              sizes="(min-width: 1000px) 200px,(min-width: 430px) 50vw, 100vw"
              alt={thumbnail?.alt ?? ''}
            />
          )}
        </MediaPlaceholder>
        <Text>
          <PlayIcon />
          {title ?? t('watchInMotion')}
        </Text>
      </ClickableWrap>
      <Modal
        isOpen={isVideoModalOpen}
        onRequestClose={() => setVideoModalOpen(false)}
        overlayCloseButton
        variant={'rounded'}
        overlayVariant="blur"
      >
        <VideoModalContentWrap>
          <YouTubePlayer videoId={getYouTubeIdFromUrl(youtubeVideoUrl)} />
        </VideoModalContentWrap>
      </Modal>
    </>
  )
}
