import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { SectionHeroProps } from '@liftfoils/models'
import { Link, PortableText, Gradient, Media } from '@liftfoils/components'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  position: 'relative',
  $posterHeight: true,
  display: 'grid',
})

const Overlay = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  display: 'grid',
  gridTemplateRows: '1fr min-content 1fr',
  px: '$containerMargin',
  $py: 'L',
  zIndex: 1,
  gridGap: '$8',
  justifyItems: 'center',
})

const CenterWrap = styled('div', {
  gridRow: 2,
  alignSelf: 'center',
})
const Heading = styled('h1', {
  lift_font: 'caps01',
  color: 'white',
  textAlign: 'center',
})
const Description = styled('div', {
  lift_font: 'heading02',
  textAlign: 'center',
  maxWidth: '400px',
  color: '$white_06',
  '.highlighted': { color: 'white' },
})

const BottomWrap = styled('div', {
  gridRow: 3,
  display: 'grid',
  gridGap: '$8',
  alignSelf: 'end',
  justifyItems: 'center',
})
const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})

export const SectionHero: FC<SectionHeroProps> = ({
  background,
  title,
  content,
  ctaLabel,
  ctaLink,
  priority,
}) => {
  return (
    <Wrap>
      {background && (
        <MediaWrap>
          <Media
            {...background}
            layout="fill"
            alt={background?.alt ?? ''}
            priority={priority}
            sizes="100vw"
            hardcropRatio={{
              '856': 'square',
              '1440': 'landscape2',
              '1920': 'landscape2',
              '2880': 'landscape1',
            }}
            controlsPosition={'bottom'}
          />
          <Gradient from={'bottom'} css={{ height: '75%' }} />
        </MediaWrap>
      )}
      <Overlay>
        <CenterWrap>{title && <Heading>{title}</Heading>}</CenterWrap>

        <BottomWrap>
          {content && (
            <Description>
              <PortableText value={content} />
            </Description>
          )}
          {validateLink(ctaLink) && (
            <Link size={'medium'} {...ctaLink} appearance={'solidWhite'}>
              {ctaLabel}
            </Link>
          )}
        </BottomWrap>
      </Overlay>
    </Wrap>
  )
}
