import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { MapFiltersType } from '@liftfoils/models'

const Wrap = styled('span', {
  display: 'inline-block',
  borderRadius: '50%',
  variants: {
    type: {
      affiliate: {
        background: '$teal500',
      },
      'affiliate-reseller': {
        background:
          'linear-gradient(90deg, $teal500 0%, $teal500 50%, $red500 50%, $red500 100%)',
      },
      reseller: {
        background: '$red500',
      },
      'classic-foil-reseller': {
        background: '$gray500',
      },
    },
    size: {
      default: {
        width: '29px',
        height: '29px',
      },
      medium: {
        width: '35px',
        height: '35px',
      },
      large: {
        width: '40px',
        height: '40px',
      },
    },
  },
})

interface FilterTypeMarkerProps {
  type: MapFiltersType
  htmlFor?: string
  className?: string
  size?: 'default' | 'large' | 'medium'
}

export const FilterTypeMarker: FC<FilterTypeMarkerProps> = ({
  type,
  className,
  size = 'default',
}) => {
  return <Wrap type={type} className={className} size={size} />
}
