import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { PinContent } from './PinContent'
import { FilterTypeMarker } from './FilterTypeMarker'
import { filters } from '../filters'
import { MapPin } from '@liftfoils/models'

const Wrap = styled('div', {
  background: 'white',
  padding: '$9',
  borderRadius: '$r3',
})

const Header = styled('div', {
  display: 'flex',
  margin: '0 0 $8 0',
})

const StyledFilterTypeMarker = styled(FilterTypeMarker, {
  flexShrink: 0,
  margin: '0 $6 0 0',
})

const TitleContent = styled('div', {})

const Title = styled('h3', {
  lift_font: 'body02',
  margin: '0 0 $4 0',
})

const Type = styled('div', {
  lift_font: 'body03',
})

const StyledPinContent = styled(PinContent, {
  padding: '0',
})

type ListItemProps = {
  item: MapPin
}

export const ListItem: FC<ListItemProps> = ({ item }) => {
  const filter = filters.byName[item.type]

  return (
    <Wrap>
      <Header>
        <StyledFilterTypeMarker type={item.type} size="default" />
        <TitleContent>
          <Title>{item.name}</Title>
          <Type>{filter.label}</Type>
        </TitleContent>
      </Header>

      <StyledPinContent item={item} />
    </Wrap>
  )
}
