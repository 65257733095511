import { FC } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { styled } from '@liftfoils/styles'
import {
  EventsLandingEventProps,
  SectionEventsLandingProps,
} from '@liftfoils/models'

import { Button, Link, Media, StyledClickable } from '@liftfoils/components'
import { EventInfo } from './EventInfo'
import { getDurationString } from '@liftfoils/utils'

const Wrap = styled('div', {
  position: 'relative',
  backgroundColor: '$navy800',
  color: 'white',
})

const HeroWrap = styled('div', {
  position: 'relative',
  minHeight: '100vh',
  display: 'grid',
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  '&::before': {
    content: '',
    width: '100%',
    height: '188px',
    background:
      'linear-gradient(0deg, rgba(46,51,53,1) 1%, rgba(46,51,53,0) 100%)',
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
    pointerEvents: 'none',
  },
})

const HeroContent = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  display: 'grid',
  pointerEvents: 'none',
  gridTemplateRows: '1fr 1.5fr 2fr',
  '@sm': {
    gridTemplateRows: '1fr 1fr 1fr',
  },
})

const CenterWrap = styled('div', {
  $hasContainerMX: true,
  display: 'grid',
  alignContent: 'center',
  gridRowStart: 2,
  zIndex: 1,
})

const Heading = styled('h1', {
  lift_font: 'caps02',
  color: 'white',
  textAlign: 'center',
  my: '$5',
  '@md': {
    textAlign: 'left',
  },
})

const FeaturedEventWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  $hasContainerMX: true,
  columnGap: '$8',
  rowGap: '$8',
  alignSelf: 'center',
  gridRowStart: 3,

  '@md': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
})

const FeaturedEventInfo = styled('div', {
  '@md': {
    maxWidth: '500px',
  },
})

const FeaturedEventHeading = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  mb: '$8',
  gap: '$8',
  '@md': {
    flexWrap: 'nowrap',
  },
})

const FeaturedEventTitle = styled('div', {
  lift_font: 'heading02',
  color: 'white',
  textTransform: 'uppercase',
  mt: '-6px',
})
const FeaturedEventDatePlace = styled('div', {
  lift_font: 'heading03',
  color: 'white',
})

const ButtonWrapper = styled('div', {
  pointerEvents: 'all',
  width: '200px',
  lift_font: 'body02',
})
const Filters = styled('div', {
  display: 'flex',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  alignItems: 'center',
  pl: '$containerMargin',
  py: '$10',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '> button': {
    minWidth: '72px',
    justifyContent: 'center',
    $mr: 'XS',
    '@sm': {
      minWidth: '87px',
    },
  },
})

const Buffer = styled('div', {
  display: 'block',
  minWidth: '5vw',
  height: '1px',
})

const FiltersLabel = styled('span', {
  lift_font: 'body03',
  color: '$white_06',
  width: 'max-content',
  $mr: 'XS',
  $py: 'S',
  whiteSpace: 'nowrap',
})
const Events = styled('div', {
  $hasContainerMX: true,
  py: '$10',
})
const EventsList = styled('div', {
  my: '$6',
  borderTop: '1px solid $white_02',
  borderBottom: '1px solid $white_02',
  backgroundColor: '$white_02',
  display: 'grid',
  gridGap: '1px',
})
const EventsType = styled('p', {
  lift_font: 'heading02',
})
const Empty = styled('p', {
  backgroundColor: '$navy800',
  py: '$8',
  lift_font: 'body02',
  color: '$white_06',
})

export const SectionEventsLanding: FC<SectionEventsLandingProps> = ({
  heading,
  featuredEvent,
  upcomingEvents,
  pastEvents,
  priority,
  ongoingEvents,
}) => {
  const { t } = useTranslation('events')
  const { query, replace } = useRouter()

  const FILTERS = [
    {
      name: t('all').toLowerCase(),
      label: t('all'),
    },
    {
      name: t('session').toLowerCase(),
      label: t('session'),
    },
    {
      name: t('class').toLowerCase(),
      label: t('class'),
    },
    {
      name: t('launch').toLowerCase(),
      label: t('launch'),
    },
  ]

  const currentCategory = (event: EventsLandingEventProps): boolean =>
    event?.category?.toLowerCase() === query.filter ||
    !query.filter ||
    query.filter === FILTERS[0].name

  const filterClick = (i: number) => {
    replace(
      {
        query: {
          ...query,
          filter: FILTERS[i].name,
        },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  return (
    <Wrap>
      <HeroWrap>
        {featuredEvent?.background && (
          <MediaWrap>
            <Link
              {...(featuredEvent?.path
                ? { as: Link, href: featuredEvent?.path }
                : { as: 'div' })}
            >
              <Media
                {...featuredEvent.background}
                layout={'fill'}
                priority={priority}
                sizes="100vw"
                alt={featuredEvent?.background?.alt ?? ''}
              />
            </Link>
          </MediaWrap>
        )}
        <HeroContent>
          <CenterWrap>{heading && <Heading>{heading}</Heading>}</CenterWrap>
          <FeaturedEventWrap>
            {featuredEvent && (
              <FeaturedEventInfo>
                <FeaturedEventHeading>
                  <StyledClickable
                    as={'div'}
                    appearance={'blur'}
                    size={'small'}
                  >
                    {featuredEvent.category}
                  </StyledClickable>
                  <FeaturedEventTitle>{featuredEvent.title}</FeaturedEventTitle>
                </FeaturedEventHeading>
                <FeaturedEventDatePlace>
                  {getDurationString(
                    featuredEvent.startDate,
                    featuredEvent.endDate,
                  )}
                </FeaturedEventDatePlace>
              </FeaturedEventInfo>
            )}
            <ButtonWrapper>
              <Link
                href={featuredEvent?.path ? featuredEvent.path : '#'}
                appearance={'solidWhite'}
                size="wide"
              >
                {t('viewEvent')}
              </Link>
            </ButtonWrapper>
          </FeaturedEventWrap>
        </HeroContent>
      </HeroWrap>
      <Filters>
        <FiltersLabel>{t('filterBy')}</FiltersLabel>
        {FILTERS.map((filter, filterIndex) => {
          return (
            <Button
              key={filterIndex}
              size={'small'}
              appearance={
                filter.name === query.filter ||
                (filterIndex === 0 && !query.filter)
                  ? 'solidWhite'
                  : 'blur'
              }
              onClick={() => filterClick(filterIndex)}
            >
              {filter.label}
            </Button>
          )
        })}
        <Buffer />
      </Filters>
      {ongoingEvents && ongoingEvents.length > 0 && (
        <Events>
          <EventsType>{t('ongoingEvents')}</EventsType>
          <EventsList>
            {ongoingEvents?.some((event) => currentCategory(event)) ? (
              ongoingEvents?.map((event) => (
                <EventInfo
                  key={event.path}
                  startDate={event.startDate}
                  endDate={event.endDate}
                  address={event.address}
                  title={event.title}
                  introduction={event.introduction}
                  path={event.path}
                  category={event.category}
                  isVisible={currentCategory(event)}
                />
              ))
            ) : (
              <Empty>{t('emptyCategory')}</Empty>
            )}
          </EventsList>
        </Events>
      )}
      <Events>
        <EventsType>{t('upcomingEvents')}</EventsType>
        <EventsList>
          {upcomingEvents?.some((event) => currentCategory(event)) ? (
            upcomingEvents?.map((event) => (
              <EventInfo
                key={event.path}
                startDate={event.startDate}
                endDate={event.endDate}
                address={event.address}
                title={event.title}
                introduction={event.introduction}
                path={event.path}
                category={event.category}
                isVisible={currentCategory(event)}
              />
            ))
          ) : (
            <Empty>{t('emptyCategory')}</Empty>
          )}
        </EventsList>
      </Events>
      <Events>
        <EventsType>{t('pastEvents')}</EventsType>
        <EventsList>
          {pastEvents?.some((event) => currentCategory(event)) ? (
            pastEvents?.map((event) => (
              <EventInfo
                key={event.path}
                startDate={event.startDate}
                endDate={event.endDate}
                address={event.address}
                title={event.title}
                category={event.category}
                isVisible={currentCategory(event)}
                withYear={true}
              />
            ))
          ) : (
            <Empty>{t('emptyCategory')}</Empty>
          )}
        </EventsList>
      </Events>
    </Wrap>
  )
}
