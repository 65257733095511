import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Button } from '@liftfoils/components'
import { MoreInfo } from '@liftfoils/icons'

type OptionHeaderProps = {
  title: string
  buttonLabel?: string
  onButtonClick?: () => void
  disabled: boolean
}

const Root = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
})

const Title = styled('h3', {
  lift_font: 'heading03',
  color: '$gray400',
})

const StyledButton = styled(Button, {
  lift_font: 'body03',
  fontSize: 14,
  color: '$gray400',
  gridAutoFlow: 'column',
  alignItems: 'center',
  padding: '$2 $4',

  '&> span': {
    mr: '$5',
  },

  '&:hover': {
    color: '$gray500',
  },
})

export const OptionHeader: FC<OptionHeaderProps> = ({
  title,
  buttonLabel,
  onButtonClick,
  disabled,
}) => {
  return (
    <Root>
      <Title>{title}</Title>
      {buttonLabel && (
        <StyledButton
          onClick={onButtonClick}
          appearance={'flex'}
          disabled={disabled}
        >
          <span>{buttonLabel}</span>
          <MoreInfo />
        </StyledButton>
      )}
    </Root>
  )
}
