import { FC } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { styled } from '@liftfoils/styles'
import { SectionStoriesLandingProps } from '@liftfoils/models'

import {
  Link,
  Container,
  Button,
  StyledClickable,
  StoryCard,
  Media,
} from '@liftfoils/components'

const Wrap = styled('div', {
  position: 'relative',
  backgroundColor: '$navy800',
})

const TopWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  $posterHeight: true,
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  '&::before': {
    content: '',
    width: '100%',
    height: '188px',
    background:
      'linear-gradient(0deg, rgba(46,51,53,1) 1%, rgba(46,51,53,0) 100%)',
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
  },
})

const Content = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  zIndex: 1,
  display: 'grid',
  gridTemplateRows: '1fr 1.5fr 2fr',
  height: '100%',
  '@sm': {
    gridTemplateRows: '1fr 1fr 1fr',
  },
})

const CenterWrap = styled('div', {
  $hasContainerMX: true,
  display: 'grid',
  alignContent: 'center',
  gridRowStart: 2,
  zIndex: 1,
})

const Heading = styled('h1', {
  lift_font: 'caps02',
  color: 'white',
  my: '$5',
  textAlign: 'center',
  '@md': {
    textAlign: 'left',
  },
})

const BottomWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  $hasContainerMX: true,
  columnGap: '$8',
  rowGap: '$6',
  alignSelf: 'center',
  gridRowStart: 3,

  '@md': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
})

const StoryInfo = styled('div', {
  '@md': {
    maxWidth: '418px',
  },
})

const StoryHeading = styled('div', {
  display: 'flex',
  mb: '$8',
})

const RelatedStoryTitle = styled('div', {
  lift_font: 'heading02',
  color: 'white',
  $ml: 'S',
  mt: '-6px',
})

const Description = styled('p', {
  lift_font: 'heading03',
  mb: '$8',
  color: 'white',
})

const ButtonWrapper = styled('div', {})

const Stories = styled('div', {
  backgroundColor: '$navy800',
  color: 'white',
  zIndex: 99,
})

const Filters = styled('div', {
  display: 'flex',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  alignItems: 'center',
  pl: '$containerMargin',
  py: '$10',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '> button': {
    minWidth: '72px',
    justifyContent: 'center',
    $mr: 'XS',
    '@sm': {
      minWidth: '87px',
    },
  },
})

const Buffer = styled('div', {
  display: 'block',
  minWidth: '5vw',
  height: '1px',
})

const FiltersLabel = styled('span', {
  lift_font: 'body03',
  color: '$white_06',
  width: 'max-content',
  $mr: 'XS',
  $py: 'S',
  whiteSpace: 'nowrap',
})

const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gridColumnGap: '$8',
  gridRowGap: '$10',
  pb: '$12',
  '@sm': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@md': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@xl': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})

export const SectionStoriesLanding: FC<SectionStoriesLandingProps> = ({
  title,
  shortDescription,
  featuredStory,
  stories,
  filters,
  priority,
}) => {
  const { t } = useTranslation('stories')
  const { query, replace } = useRouter()

  const FILTERS = [{ title: 'All', slug: 'all' }, ...filters]

  const filterClick = (i: number) => {
    replace(
      {
        query: {
          ...query,
          filter: FILTERS[i].slug,
        },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  return (
    <Wrap>
      <Link href={featuredStory?.path} tabIndex={-1}>
        <TopWrap>
          {featuredStory?.background && (
            <MediaWrap>
              <Media
                {...featuredStory.background}
                layout="fill"
                priority={priority}
                sizes="100vw"
                hardcropRatio={{
                  '856': 'square',
                  '1440': 'landscape2',
                  '1920': 'landscape2',
                  '2880': 'landscape1',
                }}
                alt={featuredStory.background?.alt ?? ''}
              />
            </MediaWrap>
          )}

          <Content>
            <CenterWrap>{title && <Heading>{title}</Heading>}</CenterWrap>
            <BottomWrap>
              <StoryInfo>
                <StoryHeading>
                  {featuredStory?.category && (
                    <StyledClickable
                      as={'div'}
                      appearance={'blur'}
                      size={'small'}
                      css={{
                        pointerEvents: 'none',
                      }}
                    >
                      {featuredStory.category}
                    </StyledClickable>
                  )}
                  {featuredStory?.title && (
                    <RelatedStoryTitle>{featuredStory.title}</RelatedStoryTitle>
                  )}
                </StoryHeading>
                {shortDescription && (
                  <Description>{shortDescription}</Description>
                )}
              </StoryInfo>
              <ButtonWrapper>
                <Button appearance={'solidWhite'} size={'large'}>
                  {t('readArticle')}
                </Button>
              </ButtonWrapper>
            </BottomWrap>
          </Content>
        </TopWrap>
      </Link>
      <Stories>
        <Filters>
          <FiltersLabel>{t('filterBy')}</FiltersLabel>
          {FILTERS.map((filter, filterIndex) => {
            return (
              <Button
                key={filterIndex}
                size={'small'}
                appearance={
                  filter.slug === query.filter ||
                  (filterIndex === 0 && !query.filter)
                    ? 'solidWhite'
                    : 'blur'
                }
                onClick={() => filterClick(filterIndex)}
              >
                {filter.title}
              </Button>
            )
          })}
          <Buffer />
        </Filters>
        <Container>
          <Grid>
            {stories &&
              stories.map((storyItem) => {
                return (
                  <StoryCard
                    title={storyItem.title}
                    key={storyItem.title}
                    category={storyItem.category}
                    path={storyItem.path}
                    date={storyItem.date}
                    background={storyItem.background}
                    isVisible={
                      storyItem?.category?.toLowerCase() === query.filter ||
                      !query.filter ||
                      query.filter === FILTERS[0].slug
                    }
                    sizes="(min-width: 1300px) 25vw, (min-width: 740px) 32vw, (min-width: 430px) 50vw, 100vw"
                  />
                )
              })}
          </Grid>
        </Container>
      </Stories>
    </Wrap>
  )
}
