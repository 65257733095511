import { Button, Media } from '@liftfoils/components'
import { ArrowLeft, ArrowRight } from '@liftfoils/icons'
import { MediaFieldType } from '@liftfoils/models'
import { ShopifyResolvedVariantBasic } from '@liftfoils/shopify-sdk'
import { styled } from '@liftfoils/styles'
import debounce from 'lodash.debounce'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'
import { GRADIENT_BG } from '../config'
import { Model3DViewer } from './Model3D'

const Root = styled('div', {
  display: 'grid',
  gridArea: 'gallery',
  top: '$headerHeight',
  bg: GRADIENT_BG,
  '@md': {
    px: '$containerMargin',
  },
  '@lg': {
    px: '0',
    top: '0',
    position: 'relative',
    bg: 'none',
    height: '100%',
  },
})

const ProductMedia = styled('div', {
  position: 'relative',
  gridRow: 1,
  gridColumn: 1,
  display: 'none',
  opacity: 0,
  variants: {
    active: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },

  '@md': {
    display: 'grid',
  },
})

const MobileSlider = styled('div', {
  display: 'flex',
  height: '100%',
  width: '100%',
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  position: 'absolute',
  top: '0',

  '& > *': {
    flexShrink: '0',
  },

  '@md': {
    display: 'none',
  },

  variants: {
    isSnapEnabled: {
      true: {
        scrollSnapAlign: 'center',
      },
      false: {
        scrollSnapAlign: 'unset',
      },
    },
  },
})

const ProductGalleryButtonWrap = styled('div', {
  gridArea: '1/1',
  zIndex: 3,
  alignSelf: 'center',
  display: 'none',

  '@md': {
    display: 'inline-flex',
  },
})

const GallerySectionWrap = styled('div', {
  display: 'grid',
})
const ProductGalleryStickyWrap = styled('div', {
  position: 'sticky',
  top: '$headerHeight',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  height: '80vw',

  '@lg': {
    py: '$headerHeight',
    height: 'calc(100vh - $headerHeight)',
  },
})

const MobileMediaContainer = styled('div', {
  height: '100%',
  width: '100%',
  scrollSnapAlign: 'center',
})
type MobileMediaWrapProps = {
  slider?: HTMLDivElement | null
  setActiveIndex: (index: number) => void
  index: number
}
const MobileMediaWrap: FC<PropsWithChildren<MobileMediaWrapProps>> = ({
  slider,
  setActiveIndex,
  index,
  children,
}) => {
  const [ref, isInView] = useInView({
    root: slider,
    threshold: 0.9,
  })

  useEffect(() => {
    if (isInView) {
      setActiveIndex(index)
    }
  }, [isInView])

  return <MobileMediaContainer ref={ref}>{children}</MobileMediaContainer>
}

const MobileProductGalleryButtonWrap = styled('div', {
  display: 'flex',
  position: 'absolute',
  right: '5vw',
  bottom: '$8',
  height: '$buttonHeightM',
  alignItems: 'center',

  button: {
    display: 'inline-flex',
    '& + &': {
      marginLeft: '$6',
    },
  },

  '@md': {
    display: 'none',
  },

  variants: {
    isHidden: {
      true: {
        display: 'none',
      },
    },
  },
})

type Props = {
  variant?: ShopifyResolvedVariantBasic
  productImages?: MediaFieldType[]
  activeGallerySection: 'photo' | 'model3d'
  current3DModelUUID?: string
}

const MediaGallery = ({
  variant,
  productImages,
  activeGallerySection,
  current3DModelUUID,
}: Props) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0)
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const [isScrolled, setScrolled] = useState(false)

  const images = useMemo(() => {
    const variantImages = variant?.metafields.images?.map((i) => ({
      mediaPayload: i,
    }))
    return variantImages && variantImages.length > 0
      ? variantImages
      : productImages ?? []
  }, [variant, productImages])

  const navigate = (change: number) => {
    setActiveImageIndex(
      Math.max(0, Math.min(images.length - 1, activeImageIndex + change)),
    )
  }
  const stopScrollAnimation = useCallback(
    debounce(() => setScrolled(false), 800),
    [],
  )
  const scrollBySlides = (numberOfSlides: number) => {
    if (sliderRef?.current) {
      setScrolled(true)
      stopScrollAnimation()
      const currentSlideWidth = sliderRef?.current
        ? sliderRef.current.children[1].clientWidth
        : 0
      sliderRef.current?.scrollBy({
        left: numberOfSlides * currentSlideWidth,
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  // Reset current image on variant change
  useEffect(() => {
    setActiveImageIndex(0)
    sliderRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [variant])

  return (
    <Root>
      <ProductGalleryStickyWrap>
        {activeGallerySection === 'photo' && (
          <GallerySectionWrap>
            {images.length > 1 && (
              <ProductGalleryButtonWrap
                css={{
                  justifySelf: 'start',
                }}
              >
                <Button
                  disabled={activeImageIndex === 0}
                  appearance="solidWhite"
                  size="small"
                  onClick={() => navigate(-1)}
                >
                  <ArrowLeft />
                </Button>
              </ProductGalleryButtonWrap>
            )}

            {images?.map((image, index) => (
              <ProductMedia active={activeImageIndex === index} key={index}>
                <Media
                  {...image}
                  priority={index === 0}
                  objectFit="contain"
                  layout="fill"
                  sizes={'(min-width: 1000px) 70vw, 100vw'}
                />
              </ProductMedia>
            ))}
            {images.length > 1 && (
              <ProductGalleryButtonWrap
                css={{
                  justifySelf: 'end',
                }}
              >
                <Button
                  disabled={activeImageIndex === images.length - 1}
                  appearance="solidWhite"
                  size="small"
                  onClick={() => navigate(1)}
                >
                  <ArrowRight />
                </Button>
              </ProductGalleryButtonWrap>
            )}
            {images.length > 1 && (
              <MobileSlider ref={sliderRef} isSnapEnabled={!isScrolled}>
                {images?.map((image, index: number) => (
                  <MobileMediaWrap
                    index={index}
                    setActiveIndex={setActiveImageIndex}
                    slider={sliderRef?.current}
                    key={index}
                  >
                    <Media
                      {...image}
                      priority={index === 0}
                      objectFit="contain"
                      layout="fill"
                      sizes={'(min-width: 1000px) 70vw, 100vw'}
                    />
                  </MobileMediaWrap>
                ))}
              </MobileSlider>
            )}
          </GallerySectionWrap>
        )}
        {activeGallerySection === 'model3d' && (
          <GallerySectionWrap>
            <Model3DViewer model3dUUID={current3DModelUUID} />
          </GallerySectionWrap>
        )}
        <MobileProductGalleryButtonWrap
          isHidden={activeGallerySection !== 'photo'}
        >
          {images.length > 1 && (
            <Button
              disabled={activeImageIndex === 0}
              appearance="solidWhite"
              size="small"
              onClick={() => scrollBySlides(-1)}
            >
              <ArrowLeft />
            </Button>
          )}
          {images.length > 1 && (
            <Button
              disabled={activeImageIndex === images.length - 1}
              appearance="solidWhite"
              size="small"
              onClick={() => scrollBySlides(+1)}
            >
              <ArrowRight />
            </Button>
          )}
        </MobileProductGalleryButtonWrap>
      </ProductGalleryStickyWrap>
    </Root>
  )
}

export default MediaGallery
