import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from '@liftfoils/styles'
import { SectionEditorialHeroProps } from '@liftfoils/models'
import {
  Link,
  Container,
  Button,
  YouTubePlayer,
  PortableText,
  VideoModalContentWrap,
  Modal,
  Media,
} from '@liftfoils/components'

import { getYouTubeIdFromUrl } from '@liftfoils/utils'
import { ArrowLeft } from '@liftfoils/icons'

const Wrap = styled('div', {
  backgroundColor: '$navy800',
  color: 'white',
})

const HeaderWrap = styled('div', {
  position: 'relative',
  $posterHeight: true,
  display: 'grid',
  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
      },
    },
  },
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  '&::before': {
    content: '',
    width: '100%',
    height: '19em',
    background:
      'linear-gradient(0deg, rgba(46,51,53,1) 5%, rgba(46,51,53,0) 100%)',
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
  },
})

const ButtonWrap = styled('div', {
  display: 'grid',
  gridGap: '$9',
  justifyItems: 'center',
  gridRow: 1,
  gridColumn: 1,
  alignSelf: 'center',
  zIndex: 1,
})

const ContentWrap = styled('div', {
  position: 'relative',
  zIndex: 1,
  mt: '-17em',
  pt: '$10',
  pb: '$11',
  display: 'grid',
})

const NavWrap = styled('div', {
  display: 'grid',
  width: '100%',
  '@md': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
})

const LinkLabel = styled('span', {
  lift_font: 'body03',
  ml: '$7',
})

const CategoryWrapper = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  $mt: 'L',
  '@md': {
    mt: '$0',
  },
})

const Heading = styled('h1', {
  lift_font: 'caps03',
  color: 'white',
  textAlign: 'center',
  alignSelf: 'center',
  justifySelf: 'center',
  $py: 'M',
  zIndex: 1,
  maxWidth: '798px',
})

const Date = styled('p', {
  lift_font: 'body03',
  color: '$white_06',
  textAlign: 'center',
  justifySelf: 'center',
  '+ *': {
    mt: '$5',
  },
})

const Description = styled('div', {
  lift_font: 'heading03',
  textAlign: 'center',
  maxWidth: '568px',
  color: '$white_06',
  justifySelf: 'center',
  '.highlighted': { color: 'white' },
})
export const SectionEditorialHero: FC<SectionEditorialHeroProps> = ({
  title,
  background,
  headerVideo,
  category,
  categoryPath,
  parentPath,
  date,
  shortDescription,
  priority,
}) => {
  const [isMainVideoModalOpen, setMainVideoModalOpen] = useState<boolean>(false)
  const { t } = useTranslation(parentPath.substring(1))

  const toggleVideoModal = () => {
    setMainVideoModalOpen(!isMainVideoModalOpen)
  }

  return (
    <Wrap>
      {headerVideo?.url && (
        <Modal
          isOpen={isMainVideoModalOpen}
          onRequestClose={toggleVideoModal}
          overlayCloseButton
          variant={'rounded'}
          overlayVariant={'blur'}
        >
          <VideoModalContentWrap>
            <YouTubePlayer videoId={getYouTubeIdFromUrl(headerVideo.url)} />
          </VideoModalContentWrap>
        </Modal>
      )}
      <HeaderWrap clickable={!!headerVideo?.url} onClick={toggleVideoModal}>
        <MediaWrap>
          <Media
            {...background}
            layout={'fill'}
            priority={priority}
            sizes="100vw"
            hardcropRatio={{
              '856': 'square',
              '1440': 'landscape2',
              '1920': 'landscape2',
              '2880': 'landscape1',
            }}
            alt={background?.alt ?? ''}
          />
        </MediaWrap>
        {headerVideo?.url && (
          <ButtonWrap>
            <Button
              size={'medium'}
              appearance={'blur'}
              onClick={toggleVideoModal}
            >
              {t('watchFullVideo')}
            </Button>
          </ButtonWrap>
        )}
      </HeaderWrap>
      <Container>
        <ContentWrap>
          <NavWrap>
            <Link href={parentPath}>
              <Button size={'small'} appearance={'solidGray500'}>
                <ArrowLeft />
              </Button>
              <LinkLabel>{t('back')}</LinkLabel>
            </Link>
            <CategoryWrapper>
              <Link
                href={categoryPath}
                size={'small'}
                appearance={'solidGray500'}
              >
                {t(category)}
              </Link>
            </CategoryWrapper>
          </NavWrap>
          <Heading>{title}</Heading>
          <Date>{date}</Date>
          {shortDescription && (
            <Description>
              <PortableText value={shortDescription} />
            </Description>
          )}
        </ContentWrap>
      </Container>
    </Wrap>
  )
}
