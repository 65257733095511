import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Star } from '@liftfoils/icons'
import { useTranslation } from 'react-i18next'
import { Button } from '@liftfoils/components'

type ReviewsBadgeProps = {
  rating: number
  ratingCount: number
}

const Root = styled(Button, {
  padding: 0,
  margin: 0,
  background: 'transparent',
})
const Inner = styled('div', {
  borderRadius: '$r1',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '$gray500_06',
  backgroundColor: '$gray500_01',
  width: 'fit-content',
  marginTop: '$4',
  padding: '$5 $7',
  '@lg': {
    backgroundColor: '$white_06',
    marginTop: 0,
  },
})
const Rating = styled('span', {
  lift_font: 'body06',
  color: '$gray500',
  marginLeft: '$4',
})

const Divider = styled('div', {
  width: 1,
  height: '$6',
  backgroundColor: '$gray500_06',
  margin: '0 $5',
})

const Reviews = styled('span', {
  lift_font: 'body06',
})

export const ReviewsBadge: FC<ReviewsBadgeProps> = ({
  rating,
  ratingCount,
}) => {
  const { t } = useTranslation('product')

  return (
    <Root
      onClick={() => {
        const elem = document.getElementById('stamped-main-widget')
        if (elem) {
          window.scrollTo({
            top: elem.offsetTop,
            behavior: 'smooth',
          })
        }
      }}
    >
      <Inner>
        <Star />
        <Rating>{rating}</Rating>
        <Divider />
        <Reviews>{t('review', { count: ratingCount })}</Reviews>
      </Inner>
    </Root>
  )
}
