import { styled } from '@liftfoils/styles'
import { Button, Modal } from '@liftfoils/components'
import { Close } from '@liftfoils/icons'
import { useTranslation } from 'react-i18next'
import { SendProductConfigForm } from '@liftfoils/forms'

const Content = styled('div', {
  maxWidth: '100%',
  p: '$9',
  width: '400px',
  display: 'grid',
  gridGap: '$7',
  textAlign: 'center',
  lift_font: 'body02',
})

const Title = styled('p', {
  mb: '$3',
})

const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  width: '$11',
  height: '$11',
  display: 'grid',
  placeItems: 'center',
})

type ModalAssistanceProps = {
  isOpen: boolean
  handleClose: () => void
  attributes?: { key: string; value: string }[]
}

export const SendProductConfigModal = ({
  isOpen,
  handleClose,
  attributes,
}: ModalAssistanceProps) => {
  const { t } = useTranslation('forms')

  const handleModalClose = () => {
    handleClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      variant={'white-rounded'}
    >
      <>
        <CloseButtonWrapper>
          <Button onClick={handleClose} appearance={'flex'}>
            <Close />
          </Button>
        </CloseButtonWrapper>
        <Content>
          <div>
            <Title>{t('sendProductConfigForm.title')}</Title>
          </div>
          <SendProductConfigForm attributes={attributes} />
        </Content>
      </>
    </Modal>
  )
}
