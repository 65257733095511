import { Loader, StyledClickable } from '@liftfoils/components'
import { ARIcon } from '@liftfoils/icons'
import { styled } from '@liftfoils/styles'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { detectMobileBrowser } from '@liftfoils/utils'
import Script from 'next/script'

const GRADIENT_BG =
  'radial-gradient(circle, rgba(238,238,238,1) 0%, rgba(234,234,234,1) 100%)'

const Wrap = styled('div', {
  display: 'grid',
  '& > *': {
    gridArea: '1/1/1/1',
  },
})

const ModelContainer = styled('div', {
  overflow: 'hidden',
  position: 'relative',
  display: 'grid',

  variants: {
    isLoading: {
      true: {
        visibility: 'hidden',
      },
    },
  },
})

const SayduckWrap = styled('div', {
  // New Sayduck 3D Viewer doesn't handle grid resizing well. Position absolute was used for proper scaling
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  minWidth: '100px',
  minHeight: '100px',
  width: '100%',

  '& > a': {
    display: 'none',
  },
})

const ARWrap = styled('div', {
  display: 'none',
  placeSelf: 'end',
  marginRight: '5vw',
  marginBottom: '$8',
  zIndex: 5,

  '@md': {
    marginRight: 0,
  },

  variants: {
    isMobile: {
      true: {
        display: 'block',
      },
    },
  },
})

const ARButton = styled(StyledClickable, {
  height: '$buttonHeightM',
  minWidth: '150px',

  '& > span': {
    display: 'flex',
    alignItems: 'center',
    lift_font: 'body02',
    justifyContent: 'center',

    '& svg': {
      width: '$7',
      height: '$7',
      marginRight: '$3',
    },
  },
})

const DragIndicator = styled('span', {
  lift_font: 'body02',
  placeSelf: 'center',
  color: 'white',
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  height: '$buttonHeightM',
  paddingInline: '35px',
  zIndex: 1,
  opacity: 1,
  transition: 'opacity 0.5s',
  whiteSpace: 'nowrap',
  borderRadius: '$rMax',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',

  variants: {
    isDragged: {
      true: {
        opacity: 0,
      },
    },
  },
})

const LoaderWrap = styled('div', {
  display: 'grid',
  placeItems: 'center',
  background: GRADIENT_BG,
  transition: 'opacity 0.5s',

  variants: {
    isLoading: {
      true: {
        opacity: 1,
        zIndex: 1,
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
})

const ContentLoader = styled(Loader, {
  height: '10vmin',
  width: '10vmin',
})

export const Model3DViewer = ({ model3dUUID }: { model3dUUID?: string }) => {
  const { t } = useTranslation('product')
  const [isMobile, setIsMobile] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoaded, setIsLoaded] = useState(true)
  const [isDragged, setIsDragged] = useState(false)
  const modelContainerRef = useRef<HTMLDivElement>(null)

  interface SayduckAPI {
    scene: {
      update3dViewer: (uuid: string, variant: string) => void
      setCameraAngles: (horizontalAngle: number, verticalAngle: number) => void
      launchWebAr: () => void
    }
  }

  const [api, setApi] = useState<SayduckAPI | null>(null)

  const [parsedModel3dUUID, setParsedModel3dUUID] = useState<
    string | undefined | null
  >(null)

  /**
   * ParsedModel3dUUID is to prevent rendering <ModelContainer/> and <Script/> if data-product-uuid is null
   * (render script only once, if model3dUUID exist)
   * Sayduck will not update models when data-product-uuid===null
   * (sayduck.viewer.actions.update3dViewer is not working)
   */
  useEffect(() => {
    if (model3dUUID && !parsedModel3dUUID) {
      setParsedModel3dUUID(model3dUUID)
    }
  }, [model3dUUID])

  useEffect(() => {
    setIsMobile(detectMobileBrowser())

    const handler = () => {
      setIsLoading(false)

      // Hide Sayduck logo (trick for #shadow-root)
      const shadowRoot = document?.querySelector('sayduck-viewer')?.shadowRoot
      const styleElement = shadowRoot?.querySelector('style')
      if (styleElement) {
        styleElement.textContent += `
        a {
          display: none!important;
        }
      `
      }
    }

    if (isLoaded) {
      window.addEventListener('sayduck.editor.gltfLoaded', handler)
    }
    return () => {
      window.removeEventListener('sayduck.editor.gltfLoaded', handler)
    }
  }, [isLoaded])

  useEffect(() => {
    if (isLoaded && !api) {
      window.addEventListener('sayduck.api-ready', (event) => {
        setApi((event as CustomEvent).detail.instance)
      })
    }
  }, [isLoaded])

  useEffect(() => {
    if (!isLoaded || !model3dUUID || !api) return

    api.scene.update3dViewer(model3dUUID, 'variants')
    api.scene.setCameraAngles(-35, 15)

    const ref = modelContainerRef.current
    const dragHandler = () => setIsDragged(true)

    ref?.addEventListener('mousedown', dragHandler)
    ref?.addEventListener('touchstart', dragHandler)

    return () => {
      ref?.removeEventListener('mousedown', dragHandler)
      ref?.removeEventListener('touchstart', dragHandler)
    }
  }, [model3dUUID, isLoaded, api])

  const enableAr = () => {
    if (api) {
      api.scene.launchWebAr()
    }
  }

  const SayduckViewer = `<sayduck-viewer 
  product="${parsedModel3dUUID}" 
  mode="variants" 
  background="transparent" 
  hide-loading 
  hide-controls-hint 
  hide-product-info 
  hide-photo-studio 
  hide-embed 
  hide-picker 
  hide-dimensions 
  hide-annotations 
  hide-web-ar 
  hide-action-menu 
  hide-toggle-lights 
  hide-camera-positions 
  hide-fullscreen 
  hide-camera-reset 
  hide-qr-code/>`

  return (
    <Wrap>
      {parsedModel3dUUID && (
        <>
          <ModelContainer isLoading={isLoading} ref={modelContainerRef}>
            <DragIndicator isDragged={isDragged}>
              {t('dragImage')}
            </DragIndicator>
            <SayduckWrap
              dangerouslySetInnerHTML={{ __html: SayduckViewer }}
            ></SayduckWrap>
          </ModelContainer>

          <Script
            onLoad={() => {
              setIsLoaded(true)
            }}
            src="https://viewer.new.sayduck.com/"
            type="module"
            async
          />
        </>
      )}

      <LoaderWrap isLoading={isLoading}>
        <ContentLoader />
      </LoaderWrap>
      <ARWrap isMobile={isMobile}>
        <ARButton appearance="solidWhite" onClick={enableAr}>
          <span>
            <ARIcon />
            {t('viewAR')}
          </span>
        </ARButton>
      </ARWrap>
    </Wrap>
  )
}
