import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { OptionHeader } from './OptionHeader'
import { ProductOptionValue } from './ProductOptionValue'
import { MediaFieldType, PortableTextBlocks } from '@liftfoils/models'
import { Button } from '@liftfoils/components'
import debounce from 'lodash/debounce'

import {
  ShopifyMoneyFieldsFragment,
  ShopifyResolvedVariantBasic,
} from '@liftfoils/shopify-sdk'

const Root = styled('div', {
  display: 'grid',
  gridGap: '$6',
  variants: {
    isActive: {
      false: {
        opacity: 0.3,
        pointerEvents: 'none',
      },
    },
  },
})
const Grid = styled('div', {
  display: 'grid',
  gridGap: '$5',
  variants: {
    wideElements: {
      true: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
      },
      false: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(110px, 1fr))',
      },
    },
    shortenedHeight: {
      true: {
        gridTemplateRows: 'auto auto',
        gridAutoRows: 0,
        overflow: 'hidden',
        '@sm': {
          gridTemplateRows: '1fr',
        },
      },
    },
  },
  borderRadius: '$r1',
})

const ShowMoreButton = styled(Button, {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid',
  borderColor: '$gray300_03',
  borderRadius: '$r1',
  gridColumn: -2,
  gridRow: 2,
  p: '$2',
  '@sm': {
    gridRow: 1,
  },
})

const Counter = styled('span', {
  display: 'inline-flex',
  bg: '$gray300',
  color: 'white',
  borderRadius: '$rMax',
  px: '$7',
  lift_font: 'body02',
  height: '$buttonHeightS',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
})

export type OptionValueProps = {
  optionId: string
  id?: string
  title?: string
  features?: string[]
  image?: MediaFieldType
  variantId?: string
  price?: ShopifyMoneyFieldsFragment
  additionalDescription?: PortableTextBlocks
  onChange?: (id: string, groupId: string) => void
  selected?: boolean
  disabled?: boolean
  showNotifyMe?: boolean
}

export type ProductOptionProps = {
  title: string
  id: string
  guidanceModalLabel?: string
  showGuidanceModal?: boolean
  wideElements?: boolean
  showPrice?: boolean
  expandAllValues?: boolean
  onChange?: (id: string, groupId: string) => void
  values: OptionValueProps[]
  variants?: ShopifyResolvedVariantBasic[]
  isActive?: boolean
}

export const ProductOption: FC<
  ProductOptionProps & { index?: number; openGuidanceModal?: any }
> = ({
  title,
  wideElements,
  values,
  onChange,
  id,
  expandAllValues,
  guidanceModalLabel,
  showGuidanceModal,
  index,
  openGuidanceModal,
  isActive,
}) => {
  const _expandAllValues = expandAllValues ? false : true
  const [state, setState] = useState({
    collapsed: _expandAllValues,
    collapsedItemsCount: 0,
    expandButtonVisible: !_expandAllValues,
  })

  const gridRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleResize = () => {
      let _collapsed = state.collapsed

      if (gridRef.current) {
        const hiddenChildrenCount = Array.from(gridRef.current.children).reduce(
          (acc, child, index) => {
            const hasZeroHeight = child.clientHeight === 0
            const isSelected = values[index]?.selected

            if (hasZeroHeight && isSelected) {
              _collapsed = false
            }
            return acc + (hasZeroHeight ? 1 : 0)
          },
          0,
        )

        const shouldShowMoreButtonBeVisible = state.expandButtonVisible
          ? hiddenChildrenCount > 1
          : hiddenChildrenCount > 0

        const counter =
          shouldShowMoreButtonBeVisible && hiddenChildrenCount <= 1
            ? 0
            : hiddenChildrenCount

        setState({
          collapsed: _collapsed,
          collapsedItemsCount: counter,
          expandButtonVisible: shouldShowMoreButtonBeVisible,
        })
      }
    }

    const debouncedHandleResize = debounce(handleResize, 100)

    if (_expandAllValues) {
      handleResize()
      window.addEventListener('resize', debouncedHandleResize)
    }

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [state.collapsed, state.expandButtonVisible, values, _expandAllValues])

  return (
    <Root id={id} isActive={isActive}>
      <OptionHeader
        title={title}
        buttonLabel={showGuidanceModal ? guidanceModalLabel : undefined}
        onButtonClick={() =>
          openGuidanceModal({
            title: guidanceModalLabel,
            productOptionValuesIndex: index,
          })
        }
        disabled={!isActive}
      />
      <Grid
        wideElements={!!wideElements}
        shortenedHeight={state.collapsed}
        ref={gridRef}
      >
        {values?.map(({ disabled, ...val }) => {
          return (
            <ProductOptionValue
              {...val}
              key={`${id}_${val.id}`}
              onChange={onChange}
              disabled={!isActive || disabled}
            />
          )
        })}
        {state.expandButtonVisible && state.collapsed && (
          <ShowMoreButton
            onClick={() => {
              setState({ ...state, collapsed: false })
            }}
          >
            <Counter>+{state.collapsedItemsCount}</Counter>
          </ShowMoreButton>
        )}
      </Grid>
    </Root>
  )
}
