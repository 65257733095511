import { ShopifyResolvedVariantBasic } from '@liftfoils/shopify-sdk'

export const __getVariantBySelectedOptions: (
  variants: ShopifyResolvedVariantBasic[],
  selectedOptions:
    | { name: string; value: string | string[] | undefined }[]
    | undefined,
) => ShopifyResolvedVariantBasic | undefined = (variants, selectedOptions) => {
  if (!selectedOptions) return undefined
  return variants.find((v) =>
    v.selectedOptions.every((variantOption) => {
      return (
        selectedOptions.find(
          (selectedOption) => variantOption.name === selectedOption.name,
        )?.value === variantOption.value
      )
    }),
  )
}
